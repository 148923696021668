// src/App.js

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import PasswordResetRequest from './components/PasswordResetRequest';
import AuthProvider from './contexts/AuthContext';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import Company from './components/Company';
import Inventory from './components/Inventory';
import Sales from './components/Sales';
import CashRegister from './components/CashRegister';
import UserInfo from './components/UserInfo';
import AddInventory from './components/AddInventory';
import Header from './components/Header';
import PrivateRoutes from './components/PrivateRoutes';
import Sidebar from './components/SIdebar';
import './styles.css';
import AdminApproval from './components/AdminApproval';
import TermsAndConditions from './components/TermsAndConditions';
import 'bootstrap/dist/css/bootstrap.min.css';
import ResetPassword from './components/ResetPassword';
//import CompanyApplicationManager from './components/CompanyApplicationManager';
import CompanyApplication from './components/CompanyApplication';
import ManageCompanies from './components/MC/ManageCompanies';
import JobApplication from './components/JobApplication';


const App = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
    <ThemeProvider>
      <AuthProvider>
        <Router>
          <div className="app">
            <Sidebar />
            <main className="main-content">
              <Header toggleTheme={toggleTheme} theme={theme} />
              <div className="content">
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/password-reset" element={<PasswordResetRequest />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/terms" element={<TermsAndConditions />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  
                  <Route element={<PrivateRoutes />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/companies" element={<Company />} />
                    <Route path="/inventory/:companyId" element={<Inventory />} />
                    <Route path="/sales" element={<Sales />} />
                    <Route path="/cashregister" element={<CashRegister />} />
                    <Route path="/userinfo" element={<UserInfo />} />
                    <Route path="/addinventory" element={<AddInventory />} />
                    <Route path="/adminapproval" element={<AdminApproval />} />
                    <Route path="/companyapplicant" element={<CompanyApplication />} />
                    <Route path="/managecompanies" element={<ManageCompanies />} />
                    <Route path="/jobapplication" element={<JobApplication />} />
                  </Route>
                </Routes>
              </div>
            </main>
          </div>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
