import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const PrivateRoutes = () => {
  const { user } = useAuth();
  console.log("User state in PrivateRoutes:", user);  // Add this log for debugging

  return user ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
