import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Card, Button, Spinner, Alert, Form } from 'react-bootstrap';
import withAuth from '../hoc/withAuth';

const CompanyApplication = ({ user }) => {
  const [applications, setApplications] = useState([]);
  const [error, setError] = useState('');
  const [loadingApplications, setLoadingApplications] = useState(false);

  const clearError = () => setError('');

  // Function to fetch all applications
  const fetchAllApplications = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('Token is not present, no applications to fetch.');
      return;
    }
    setLoadingApplications(true);
    clearError();
    try {
      const response = await axios.get('https://neonbs.org/api/companyApplications/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (Array.isArray(response.data)) {
        setApplications(response.data); // Set the applications state
      } else {
        setApplications([]); // Ensure we reset the applications state if the response is unexpected
      }
    } catch (error) {
      const errorMsg = `Error fetching applications: ${error.response?.data?.error || error.message}`;
      console.error(errorMsg);
      setError(errorMsg);
    } finally {
      setLoadingApplications(false);
    }
  };

  const updateApplicationStatus = async (applicationId, status, companyId, userId) => {
    const token = localStorage.getItem('token');
    try {
      await axios.put(`https://neonbs.org/api/companyApplications/${applicationId}/${status}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Assign or unassign the user based on the application status
      if (status === 'approve') {
        await axios.put(`https://neonbs.org/api/companies/${companyId}/assign`, { userId }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else if (status === 'unapprove') {
        await axios.delete(`https://neonbs.org/api/companies/${companyId}/fire/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }

      fetchAllApplications(); // Refresh the list of applications after the update
    } catch (error) {
      console.error(`Error updating application ${applicationId}:`, error);
      setError(`Error updating application ${applicationId}: ${error.response?.data?.error || error.message}`);
    }
  };

  const deleteApplication = async (applicationId) => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`https://neonbs.org/api/companyApplications/${applicationId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchAllApplications(); // Refresh the list of applications after deletion
    } catch (error) {
      console.error(`Error deleting application ${applicationId}:`, error);
      setError(`Error deleting application ${applicationId}: ${error.response?.data?.error || error.message}`);
    }
  };

  useEffect(() => {
    fetchAllApplications();
  }, []); // Fetch all applications on component mount

  // Group applications by user and company
  const groupedApplications = applications.reduce((acc, application) => {
    if (!acc[application.user.username]) {
      acc[application.user.username] = {};
    }
    acc[application.user.username][application.company.companyName] = application;
    return acc;
  }, {});

  // Extract all unique company names
  const companyNames = [...new Set(applications.map(app => app.company.companyName))];

  return (
    <Card className="company-application-card">
      <Card.Body>
        <Card.Title>Manage Company Applications</Card.Title>
        {error && <Alert variant="danger">{error}</Alert>}
        {loadingApplications ? (
          <Spinner animation="border" />
        ) : Object.keys(groupedApplications).length === 0 ? (
          <Alert variant="info">No applications found.</Alert>
        ) : (
          <Form>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>User Name</th>
                  {companyNames.map((companyName, index) => (
                    <th key={index}>{companyName}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.keys(groupedApplications).map((username) => (
                  <tr key={username}>
                    <td>{username}</td>
                    {companyNames.map((companyName, index) => {
                      const application = groupedApplications[username][companyName];
                      return (
                        <td key={index}>
                          {application ? (
                            <>
                              <span className={`status ${application.status}`}>
                                {application.status.charAt(0).toUpperCase() + application.status.slice(1)}
                              </span>
                              {application.status === 'pending' && (
                                <>
                                  <Button
                                    variant="success"
                                    onClick={() => updateApplicationStatus(application.id, 'approve', application.company.id, application.user.id)}
                                    className="mr-2"
                                  >
                                    Approve
                                  </Button>
                                  <Button
                                    variant="danger"
                                    onClick={() => updateApplicationStatus(application.id, 'reject')}
                                    className="mr-2"
                                  >
                                    Reject
                                  </Button>
                                </>
                              )}
                              {application.status === 'approved' && (
                                <Button
                                  variant="warning"
                                  onClick={() => updateApplicationStatus(application.id, 'unapprove', application.company.id, application.user.id)}
                                  className="mr-2"
                                >
                                  Unapprove
                                </Button>
                              )}
                              <Button
                                variant="danger"
                                onClick={() => deleteApplication(application.id)}
                              >
                                Delete
                              </Button>
                            </>
                          ) : (
                            <span>-</span>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Form>
        )}
      </Card.Body>
    </Card>
  );
};

export default withAuth(CompanyApplication);
