// src/hoc/withAuth.js

import React, { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom'; // Updated import
import axios from 'axios';

const withAuth = (WrappedComponent) => {
  return (props) => {
    const { user, logout } = useAuth();
    const navigate = useNavigate(); // Use useNavigate instead of useHistory

    useEffect(() => {
      const verifyToken = async () => {
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('No token found');
          }

          const response = await axios.get('/api/auth/me', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.status !== 200) {
            throw new Error('Invalid token');
          }
        } catch (error) {
          console.error('Authentication error:', error);
          // Clear any existing token and logout the user
          logout();
          // Redirect to the login page
          navigate('/login'); // Use navigate instead of history.push
        }
      };

      verifyToken();
    }, [navigate, logout]);

    // Render the wrapped component if the user is authenticated
    return user ? <WrappedComponent {...props} /> : null;
  };
};

export default withAuth;
