import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Card, Row, Col } from 'react-bootstrap';
import '../styles.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://neonbs.org/api/auth/login`, { username, password });
      
      // Store the token
      const token = response.data.token;
      localStorage.setItem('token', token);
      
      // Fetch user info (optional, based on your needs)
      await axios.get(`https://neonbs.org/api/auth/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Refresh the page to reload all components with the updated auth state
      window.location.reload();
    } catch (error) {
      console.error('Error logging in:', error);
      alert(`Login failed: ${error.response ? error.response.data.error : error.message}`);
    }
  };

  // Redirect to /cashregister on page load after the refresh
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/cashregister');
    }
  }, [navigate]);

  return (
    <Container className="neon-background-image">
      <Row className="w-100">
        <Col md={{ span: 6, offset: 3 }}>
          <Card className="p-4 neon-card">
            <Card.Body>
              <h2 className="text-center mb-4 neon-text">Login</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="username" className="mb-3">
                  <Form.Label className="neon-text">Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="neon-input"
                  />
                </Form.Group>
                <Form.Group controlId="password" className="mb-3">
                  <Form.Label className="neon-text">Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="neon-input"
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100 neon-button">
                  Login
                </Button>
              </Form>
              <p className="text-center mt-3 neon-text">
                Forgot your password? <a href="/password-reset" className="neon-link">Click here</a>
              </p>
              <p className="text-center mt-3 neon-text">
                Don't have an account? <a href="/register" className="neon-link">Register here</a>
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
