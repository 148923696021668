import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Form, Button, Container, Card, Row, Col, Spinner } from 'react-bootstrap';
import '../styles.css';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Extract the token from the URL query parameters
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  if (!token) {
    setMessage('Invalid or missing reset token.');
  }

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post('https://neonbs.org/api/auth/reset-password', {
        token,
        newPassword: password,
      });

      setMessage(response.data.message || 'Password has been reset successfully.');
      navigate('/login'); // Redirect to login after successful reset
    } catch (error) {
      console.error('Error resetting password:', error);
      setMessage(error.response?.data?.error || 'An error occurred while resetting your password.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="neon-background-image">
      <Row className="w-100">
        <Col md={{ span: 6, offset: 3 }}>
          <Card className="p-4 neon-card">
            <Card.Body>
              <h2 className="text-center mb-4 neon-text">Reset Your Password</h2>
              <Form onSubmit={handleResetPassword}>
                <Form.Group controlId="password" className="mb-3">
                  <Form.Label className="neon-text">New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter your new password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="neon-input"
                    disabled={loading}
                  />
                </Form.Group>
                <Form.Group controlId="confirmPassword" className="mb-3">
                  <Form.Label className="neon-text">Confirm New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm your new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="neon-input"
                    disabled={loading}
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100 neon-button" disabled={loading}>
                  {loading ? <Spinner animation="border" size="sm" /> : 'Reset Password'}
                </Button>
              </Form>
              {message && <p className="text-center mt-3 neon-text">{message}</p>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;
