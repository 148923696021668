import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { Container, Form, Button, Card, ListGroup, Alert, Spinner, Row, Col, InputGroup, Tabs, Tab, ButtonGroup } from 'react-bootstrap';
import '../styles.css';
import withAuth from '../hoc/withAuth';

const CashRegister = () => {
  const { user } = useAuth();
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCompanyName, setSelectedCompanyName] = useState('');
  const [items, setItems] = useState([]);
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);
  const [quantities, setQuantities] = useState({});
  const [buyerUsername, setBuyerUsername] = useState('');
  const [error, setError] = useState('');
  const [sellerUsername, setSellerUsername] = useState('');
  const [loading, setLoading] = useState(true);
  const [key, setKey] = useState('');
  const [webhookUrl, setWebhookUrl] = useState('');
  const [discount, setDiscount] = useState('none');
  const [customDiscount, setCustomDiscount] = useState('');
  const [leaderboard, setLeaderboard] = useState([]);
  const [showBuyerInfo, setShowBuyerInfo] = useState(false);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://neonbs.org/api/companies/user', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setCompanies(response.data);
        if (response.data.length > 0) {
          const savedCompanyId = localStorage.getItem('selectedCompany');
          const firstCompany = response.data.find(company => company.id === parseInt(savedCompanyId)) || response.data[0];
          setKey(firstCompany.id);
          setSelectedCompany(firstCompany.id);
          setSelectedCompanyName(firstCompany.companyName);
          setWebhookUrl(firstCompany.webhookUrl || '');
          fetchItems(firstCompany.id);
          fetchLeaderboard(firstCompany.id);

          localStorage.removeItem('selectedCompany');
        }
      } catch (error) {
        console.error('Error fetching companies:', error);
        setError('Error fetching companies');
      } finally {
        setLoading(false);
      }
    };

    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://neonbs.org/api/auth/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSellerUsername(response.data.cityName);
      } catch (error) {
        console.error('Error fetching user info:', error);
        setError('Error fetching user info');
      }
    };

    const savedScrollPosition = localStorage.getItem('scrollPosition');
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
    }

    fetchCompanies();
    fetchUserInfo();

    return () => {
      localStorage.setItem('scrollPosition', window.scrollY);
    };
  }, []);

  const fetchItems = async (companyId) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://neonbs.org/api/inventory/company/${companyId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setItems(response.data);
    } catch (error) {
      console.error('Error fetching items:', error.response ? error.response.data : error.message);
      setError('Error fetching items');
    } finally {
      setLoading(false);
    }
  };

  const fetchLeaderboard = async (companyId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://neonbs.org/api/sales/leaderboard/${companyId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setLeaderboard(response.data);
    } catch (error) {
      console.error('Error fetching leaderboard:', error.response ? error.response.data : error.message);
      setError('Error fetching leaderboard');
    }
  };

  const handleTabSelect = async (key) => {
    setKey(key);
    setSelectedCompany(key);
    const company = companies.find(company => String(company.id) === String(key));
    setSelectedCompanyName(company ? company.companyName : '');
    setWebhookUrl(company ? company.webhookUrl || '' : '');
    fetchItems(key);
    fetchLeaderboard(key);
  };

  const handleQuantityChange = (itemId, quantity) => {
    if (quantity < 0) {
      quantity = 0;
    }
    setQuantities({
      ...quantities,
      [itemId]: quantity
    });
  };

  const addItemToCart = (item) => {
    const quantity = quantities[item.id] || 1;
    if (quantity > 0) {
      const cartItem = { ...item, quantity: parseInt(quantity, 10) };
      setCart([...cart, cartItem]);
      setTotal(total + item.itemPrice * cartItem.quantity);
    }
    setQuantities({
      ...quantities,
      [item.id]: ''
    });
  };

  const removeItemFromCart = (index) => {
    const item = cart[index];
    setCart(cart.filter((_, i) => i !== index));
    setTotal(total - item.itemPrice * item.quantity);
  };

  const handleDiscountButtonClick = (value) => {
    setDiscount(value);
    if (value !== 'custom') {
      setCustomDiscount('');
    }
  };

  const handleCustomDiscountChange = (e) => {
    let value = e.target.value;
    if (value < 0) value = 0;
    if (value > 100) value = 100;
    setCustomDiscount(value);
  };

  const calculateDiscountedTotal = () => {
    let discountAmount = 0;
    if (discount === 'custom' && customDiscount) {
      discountAmount = (total * parseFloat(customDiscount)) / 100;
    } else if (discount === '10') {
      discountAmount = (total * 10) / 100;
    } else if (discount === '15') {
      discountAmount = (total * 15) / 100;
    } else if (discount === 'employee') {
      discountAmount = (total * 20) / 100;
    }
    return total - discountAmount;
  };

  const handlePay = async () => {
    const token = localStorage.getItem('token');
    if (!webhookUrl) {
      setError('No webhook URL set for this company');
      return;
    }

    const discountedTotal = calculateDiscountedTotal();

    try {
      const itemsData = cart.map(item => {
        const totalPrice = item.itemPrice * item.quantity;
        let discountPercentage = 0;

        if (discount === 'employee') {
          discountPercentage = 20;
        } else if (discount === 'custom' && customDiscount) {
          discountPercentage = parseFloat(customDiscount);
        } else if (discount === '10') {
          discountPercentage = 10;
        } else if (discount === '15') {
          discountPercentage = 15;
        }

        const discountAmount = (totalPrice * discountPercentage) / 100;
        const finalPrice = totalPrice - discountAmount;

        return {
          itemName: item.itemName,
          quantity: item.quantity,
          price: item.itemPrice,
          discountPercentage,
          total: finalPrice,
        };
      });

      const data = {
        companyId: selectedCompany,
        userId: user.id,
        items: itemsData,
        total: discountedTotal,
        discountType: discount === 'none' ? 'No Discount' : discount === 'employee' ? 'Employee Discount (20%)' : discount === 'custom' && customDiscount ? `Custom Discount (${customDiscount}%)` : `${discount}%`,
        buyerUsername,
        sellerUsername,
        companyName: selectedCompanyName,
        userRole: user.role,
      };

      const response = await axios.post('https://neonbs.org/api/sales', data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 201) {
        alert('Payment successful!');

        const webhookResponse = await axios.post('/api/webhook/send-webhook', {
          webhookUrl,
          data,
        });

        if (webhookResponse.status === 200) {
        } else {
          alert('Payment successful but failed to send webhook.');
        }

        localStorage.setItem('selectedCompany', selectedCompany);

        setCart([]);
        setTotal(0);
        setBuyerUsername('');
        setDiscount('none');
        setCustomDiscount('');

        window.location.reload();
      } else {
        alert('Payment failed!');
      }
    } catch (error) {
      console.error('Error processing payment:', error.response ? error.response.data : error.message);
      setError('Error processing payment');
      alert(`Payment failed: ${error.message}`);
    }
  };

  return (
    <Container className="mt-5">
      <h1 className="text-center mb-4">Cash Register</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      {loading ? (
        <Spinner animation="border" role="status" className="d-block mx-auto">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        companies.length > 0 ? (
          <Tabs activeKey={key} onSelect={handleTabSelect} className="mb-3">
            {companies.map((company) => (
              <Tab key={company.id} eventKey={company.id} title={company.companyName}>
                <Row>
                  <Col md={8}>
                    <Card className="mb-4" border="secondary">
                      <Card.Header>
                        <h2 className="text-center">Items</h2>
                      </Card.Header>
                      <Card.Body>
                        {loading ? (
                          <Spinner animation="border" role="status" className="d-block mx-auto">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          <Row className="flex-wrap">
                            {items.map((item) => (
                              <Col md={6} lg={4} key={item.id} className="mb-3">
                                <Card>
                                  {item.imageUrl && <Card.Img variant="top" src={`https://neonbs.org/${item.imageUrl}`} alt={item.itemName} className="inventory-image" />}
                                  <Card.Body>
                                    <Card.Title>{item.itemName}</Card.Title>
                                    <Card.Text>Price: ${item.itemPrice}</Card.Text>
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        min="0"
                                        step="1"
                                        placeholder="Quantity"
                                        value={quantities[item.id] || ''}
                                        onChange={(e) => handleQuantityChange(item.id, parseInt(e.target.value))}
                                        onKeyPress={(e) => {
                                          if (e.key === '-' || e.key === 'e' || e.key === '.') {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                      <Button variant="primary" size="sm" onClick={() => addItemToCart(item)}>Add to Cart</Button>
                                    </InputGroup>
                                  </Card.Body>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <div style={{ position: 'sticky', top: '20px' }}>
                      <Card className="mb-4" border="warning">
                        <Card.Header>
                          <h2 className="text-center">Cart & Pay</h2>
                        </Card.Header>
                        <Card.Body>
                          <ListGroup>
                            {cart.map((item, index) => (
                              <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
                                {item.itemName} - ${item.itemPrice} x {item.quantity} = ${item.itemPrice * item.quantity}
                                <Button variant="danger" size="sm" onClick={() => removeItemFromCart(index)}>Remove</Button>
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                          <h3 className="text-center mt-3">Total: ${total.toFixed(2)}</h3>
                          <Form.Group className="mb-3 text-center">
                            <Form.Label>Discount</Form.Label>
                            <ButtonGroup className="d-block">
                              <Button variant={discount === 'none' ? 'primary' : 'secondary'} onClick={() => handleDiscountButtonClick('none')}>No Discount</Button>
                              <Button variant={discount === 'employee' ? 'primary' : 'secondary'} onClick={() => handleDiscountButtonClick('employee')}>Employee Discount (20%)</Button>
                            </ButtonGroup>
                            <br />
                            <ButtonGroup className="d-block">
                              <Button variant={discount === '10' ? 'primary' : 'secondary'} onClick={() => handleDiscountButtonClick('10')}>10%</Button>
                              <Button variant={discount === '15' ? 'primary' : 'secondary'} onClick={() => handleDiscountButtonClick('15')}>15%</Button>
                              <Button variant={discount === 'custom' ? 'primary' : 'secondary'} onClick={() => handleDiscountButtonClick('custom')}>Custom</Button>
                            </ButtonGroup>
                          </Form.Group>
                          {discount === 'custom' && (
                            <Form.Group className="mb-3">
                              <Form.Label>Custom Discount (%)</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Enter custom discount percentage"
                                value={customDiscount}
                                onChange={handleCustomDiscountChange}
                                min="0"
                                max="100"
                                required
                              />
                            </Form.Group>
                          )}
                          <h3 className="text-center mt-3">Total after Discount: ${calculateDiscountedTotal().toFixed(2)}</h3>
                          <Button variant="success" size="sm" onClick={handlePay} className="d-block mx-auto">Pay</Button>
                        </Card.Body>
                      </Card>

                      <Card className="mb-4" border="info">
                        <Card.Header>
                          <h2 className="text-center">Sales Leaderboard</h2>
                        </Card.Header>
                        <Card.Body>
                          <ListGroup>
                            {leaderboard.length > 0 ? (
                              leaderboard.map((user, index) => (
                                <ListGroup.Item key={index}>
                                  {index + 1}. {user.User.cityName} - ${user.totalSales.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </ListGroup.Item>
                              ))
                            ) : (
                              <ListGroup.Item>No sales data available</ListGroup.Item>
                            )}
                          </ListGroup>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </Tab>
            ))}
          </Tabs>
        ) : (
          <Alert variant="warning" className="text-center">
            You are not hired at any company.
          </Alert>
        )
      )}
    </Container>
  );
};

export default withAuth(CashRegister);
