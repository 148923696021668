import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode'; // Correct import

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        console.log('Decoded token:', decoded);
        setUser({ id: decoded.id, role: decoded.role });
      } catch (error) {
        console.error('Invalid token:', error);
        logout(); // Logout if the token is invalid
      }
    }
  }, []);

  const login = async (username, password) => {
    try {
      const response = await axios.post('/api/auth/login', { username, password });
      const token = response.data.token;
      localStorage.setItem('token', token);
      const decoded = jwtDecode(token);
      console.log('Login successful:', decoded);
      setUser({ id: decoded.id, role: decoded.role });
    } catch (error) {
      console.error('Login failed:', error.response?.data || error.message);
      alert('Login failed. Please try again.'); // Provide feedback to the user
    }
  };

  const register = async (username, password, role) => {
    try {
      const response = await axios.post('/api/auth/register', { username, password, role });
      console.log('Registration successful:', response.data);
    } catch (error) {
      console.error('Registration failed:', error.response?.data || error.message);
      alert('Registration failed. Please try again.'); // Provide feedback to the user
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
    window.location.href = '/login'; // Redirect to login page after logout
  };

  return (
    <AuthContext.Provider value={{ user, login, register, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
