import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import { ThemeContext } from '../contexts/ThemeContext';
import { Container, Form, Button, Card, Alert, Tabs, Tab, Collapse, Row, Col } from 'react-bootstrap';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import withAuth from '../hoc/withAuth';
import '../styles.css';

const AddInventory = () => {
  const { theme } = useContext(ThemeContext);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [itemName, setItemName] = useState('');
  const [itemPrice, setItemPrice] = useState('');
  const [itemQuantity, setItemQuantity] = useState('');
  const [image, setImage] = useState(null);
  const [inventory, setInventory] = useState({});
  const [editingItemId, setEditingItemId] = useState(null);
  const [error, setError] = useState('');
  const [key, setKey] = useState('');
  const [isAddItemOpen, setIsAddItemOpen] = useState(false);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://neonbs.org/api/companies/user', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setCompanies(response.data);
        if (response.data.length > 0) {
          setKey(response.data[0].id);
          setSelectedCompany(response.data[0].id);
        }
      } catch (error) {
        console.error('Error fetching companies:', error);
        setError('Error fetching companies');
      }
    };
    fetchCompanies();
  }, []);

  const fetchInventory = useCallback(async (companyId) => {
    if (companyId) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://neonbs.org/api/inventory/company/${companyId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setInventory((prevInventory) => ({
          ...prevInventory,
          [companyId]: response.data,
        }));
      } catch (error) {
        console.error('Error fetching inventory:', error);
        setError('Error fetching inventory');
      }
    }
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      fetchInventory(selectedCompany);
    }
  }, [selectedCompany, fetchInventory]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('companyId', selectedCompany);
    formData.append('itemName', itemName);
    formData.append('itemPrice', itemPrice);
    formData.append('itemQuantity', itemQuantity);
    if (image) formData.append('image', image);

    try {
      if (editingItemId) {
        await axios.put(`https://neonbs.org/api/inventory/${editingItemId}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        setEditingItemId(null);
      } else {
        await axios.post('https://neonbs.org/api/inventory', formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      alert('Inventory item saved successfully');
      setItemName('');
      setItemPrice('');
      setItemQuantity('');
      setImage(null);
      fetchInventory(selectedCompany);
    } catch (error) {
      console.error('Error saving inventory item:', error);
      setError('Error saving inventory item');
    }
  };

  const handleDelete = async (id, companyId) => {
    const token = localStorage.getItem('token');

    try {
      await axios.delete(`https://neonbs.org/api/inventory/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setInventory((prevInventory) => ({
        ...prevInventory,
        [companyId]: prevInventory[companyId].filter((item) => item.id !== id),
      }));
    } catch (error) {
      console.error('Error deleting inventory item:', error);
      setError('Error deleting inventory item');
    }
  };

  const handleEdit = (item) => {
    setEditingItemId(item.id);
    setSelectedCompany(item.companyId);
    setItemName(item.itemName);
    setItemPrice(item.itemPrice);
    setItemQuantity(item.itemQuantity);
    setImage(null); // Reset image, assume user may want to upload a new one
    setIsAddItemOpen(true); // Open the form when editing
  };

  const handleTabSelect = async (key) => {
    setKey(key);
    if (key !== 'allCompanies') {
      setSelectedCompany(key);
      await fetchInventory(key);
    }
  };

  return (
    <Container className={`mt-5 ${theme}`}>
      <h1 className="text-center mb-4">{editingItemId ? 'Edit Inventory Item' : 'Add Inventory Item'}</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      <Tabs activeKey={key} onSelect={(k) => handleTabSelect(k)} className="mb-3">
        {companies.map((company) => (
          <Tab key={company.id} eventKey={company.id} title={company.companyName}>
            <Card className="mb-4" border="secondary">
              <Card.Header>
                <Button
                  variant="link"
                  onClick={() => setIsAddItemOpen(!isAddItemOpen)}
                  aria-controls="add-item-form"
                  aria-expanded={isAddItemOpen}
                >
                  {isAddItemOpen ? <FaAngleUp /> : <FaAngleDown />} {isAddItemOpen ? 'Hide' : 'Add'} Item Form
                </Button>
              </Card.Header>
              <Collapse in={isAddItemOpen}>
                <div id="add-item-form">
                  <Card.Body>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-3">
                        <Form.Label>Item Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Item Name"
                          value={itemName}
                          onChange={(e) => setItemName(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Item Price</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Item Price"
                          value={itemPrice}
                          onChange={(e) => setItemPrice(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Item Quantity</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Item Quantity"
                          value={itemQuantity}
                          onChange={(e) => setItemQuantity(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Item Image</Form.Label>
                        <Form.Control
                          type="file"
                          onChange={(e) => setImage(e.target.files[0])}
                        />
                      </Form.Group>
                      <Button variant="primary" type="submit">
                        {editingItemId ? 'Update Item' : 'Add Item'}
                      </Button>
                    </Form>
                  </Card.Body>
                </div>
              </Collapse>
            </Card>
            <Card border="primary">
              <Card.Header>
                <h2 className="text-center">Inventory Items</h2>
              </Card.Header>
              <Card.Body>
                <div className="inventory-container">
                  <Row className="flex-wrap">
                    {(inventory[company.id] || []).map((item) => (
                      <Col md={3} key={item.id} className="mb-3">
                        <Card>
                          <Card.Body>
                            <h3>{item.itemName}</h3>
                            <p>Price: ${item.itemPrice}</p>
                            <p>Amount in stock: {item.itemQuantity}</p>
                            {item.imageUrl && (
                              <img 
                                src={`https://neonbs.org/${item.imageUrl}`} 
                                alt={item.itemName} 
                                className="img-thumbnail inventory-image"
                              />
                            )}
                            <Button variant="danger" onClick={() => handleDelete(item.id, company.id)} className="mt-3">Delete</Button>
                            <Button variant="warning" onClick={() => handleEdit(item)} className="mt-3">Edit</Button>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Tab>
        ))}
      </Tabs>
    </Container>
  );
};

export default withAuth(AddInventory);
