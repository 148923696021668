import React from 'react';
import { Card, ListGroup, Button } from 'react-bootstrap';

const EmployeeManagement = ({ company, handleFireEmployee, user }) => {
  return (
    <Card className="employee-management-card">
      <Card.Body>
        <Card.Title>{company.companyName} Employees</Card.Title>
        <ListGroup className="mt-3">
          {company.employees.map((emp) => {
            const isManager = emp.UserCompanies?.manager;
            const isAdmin = emp.role === 'admin';
            
            return (
              <ListGroup.Item
                key={emp.id}
                className="d-flex justify-content-between align-items-center"
              >
                {emp.username} 
                {' (Employee)'}
                {isManager && ' (Manager)'}
                {isAdmin && ' (Admin)'}
                
                {(user.role === 'admin' || 
                  (user.role === 'boss' && 
                   (company.ownerId === user.id || 
                    company.employees.some(e => e.id === user.id && e.UserCompanies.manager)) && !isManager) || 
                  (user.role === 'boss' && isManager && company.ownerId === user.id)) && (
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleFireEmployee(company.id, emp.id)}
                  >
                    Fire
                  </Button>
                )}
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default EmployeeManagement;
