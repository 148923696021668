import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import { ThemeContext } from '../contexts/ThemeContext';
import {
  Container,
  Card,
  Button,
  ListGroup,
  Alert,
  Spinner,
  Form,
  Tabs,
  Tab,
  Collapse,
  Row,
  Col,
  InputGroup,
  Table,
} from 'react-bootstrap';
import { FaAngleUp, FaAngleDown, FaCog, FaEye, FaEyeSlash } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles.css';
import withAuth from '../hoc/withAuth';

const ManageCompanies = () => {
  const { theme } = useContext(ThemeContext);

  // State variables for managing component data
  const [user, setUser] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [key, setKey] = useState('adminPanel');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [sales, setSales] = useState({});
  const [totalAmount, setTotalAmount] = useState({});
  const [totalQuantity, setTotalQuantity] = useState({});
  const [monthSales, setMonthSales] = useState({});
  const [monthQuantity, setMonthQuantity] = useState({});
  const [weekSales, setWeekSales] = useState({});
  const [weekQuantity, setWeekQuantity] = useState({});
  const [topSalesRank, setTopSalesRank] = useState({});
  const [inventory, setInventory] = useState({});
  const [isAddItemOpen, setIsAddItemOpen] = useState(false);
  const [itemName, setItemName] = useState('');
  const [itemPrice, setItemPrice] = useState('');
  const [itemQuantity, setItemQuantity] = useState('');
  const [image, setImage] = useState(null);
  const [editingItemId, setEditingItemId] = useState(null);
  const [webhookUrl, setWebhookUrl] = useState('');
  const [webhookUrlSaved, setWebhookUrlSaved] = useState(false);
  const [showWebhook, setShowWebhook] = useState(false);

  // Fetch the current user's information
  useEffect(() => {
    const fetchUserInfo = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get('https://neonbs.org/api/auth/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user info:', error);
        setError('Error fetching user info');
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  // Fetch the list of companies for the user based on their role
  const fetchCompanies = useCallback(async () => {
    if (!user) return;
    try {
      const token = localStorage.getItem('token');
      const url =
        user.role === 'admin'
          ? 'https://neonbs.org/api/companies/admin'
          : 'https://neonbs.org/api/companies/user';
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      let filteredCompanies = response.data;

      // Filter companies based on the user's role
      if (user.role === 'boss') {
        filteredCompanies = filteredCompanies.filter(
          (company) =>
            company.ownerId === user.id ||
            (company.employees &&
              company.employees.some((employee) => employee.id === user.id))
        );
      } else if (user.role === 'employee') {
        filteredCompanies = filteredCompanies.filter(
          (company) =>
            company.employees &&
            company.employees.some((employee) => employee.id === user.id)
        );
      }

      // Automatically add admins as employees to all companies
      if (user.role === 'admin') {
        filteredCompanies = filteredCompanies.map((company) => ({
          ...company,
          employees: company.employees.concat(
            !company.employees.some((emp) => emp.id === user.id)
              ? [{ id: user.id, username: user.username, role: 'employee' }]
              : []
          ),
        }));
      }

      setCompanies(filteredCompanies);

      if (filteredCompanies.length > 0) {
        setKey(filteredCompanies[0].id);
        setSelectedCompany(filteredCompanies[0].id);
        fetchWebhookUrl(filteredCompanies[0].id);
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
      setError('Error fetching companies: ' + error.message);
    } finally {
      setLoading(false);
    }
  }, [user]);

  // Fetch the list of companies and users when the user data is available
  useEffect(() => {
    if (user) {
      fetchCompanies();
      if (user.role === 'admin') {
        const fetchUsers = async () => {
          const token = localStorage.getItem('token');
          try {
            const response = await axios.get('https://neonbs.org/api/users', {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            setUsers(response.data);
          } catch (error) {
            console.error('Error fetching users:', error);
            setError('Error fetching users: ' + error.message);
          }
        };
        fetchUsers();
      }
    }
  }, [user, fetchCompanies]);

  // Handle role changes for employees
  const handleRoleChange = async (userId, companyId, role, checked) => {
    const token = localStorage.getItem('token');

    try {
      if (checked) {
        await axios.put(
          `https://neonbs.org/api/companies/${companyId}/assign`,
          { userId, role },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
      } else {
        await axios.delete(
          `https://neonbs.org/api/companies/${companyId}/fire/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
      }

      // Update the local state without reloading
      setCompanies((prevCompanies) =>
        prevCompanies.map((company) =>
          company.id === companyId
            ? {
                ...company,
                employees: checked
                  ? company.employees.concat({
                      id: userId,
                      username: users.find((u) => u.id === userId)?.username,
                      role,
                    })
                  : company.employees.filter((emp) => emp.id !== userId),
              }
            : company
        )
      );
    } catch (error) {
      console.error('Error updating role:', error);
      setError('Error updating role: ' + error.message);
    }
  };

  // Handle employee termination
  const handleFireEmployee = async (companyId, userId) => {
    const token = localStorage.getItem('token');

    try {
      await axios.delete(
        `https://neonbs.org/api/companies/${companyId}/fire/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      fetchCompanies(); // Refresh data after firing employee
    } catch (error) {
      console.error('Error firing employee:', error);
      setError('Error firing employee: ' + error.message);
    }
  };

  // Handle sale deletion
  const handleDeleteSale = async (saleId, companyId) => {
    const token = localStorage.getItem('token');

    try {
      await axios.delete(`https://neonbs.org/api/sales/${saleId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      fetchSales(companyId); // Refresh sales data after deletion
    } catch (error) {
      console.error('Error deleting sale:', error);
      setError('Error deleting sale: ' + error.message);
    }
  };

  // Fetch the inventory for a specific company
  const fetchInventory = useCallback(async (companyId) => {
    if (companyId) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(
          `https://neonbs.org/api/inventory/company/${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        setInventory((prevInventory) => ({
          ...prevInventory,
          [companyId]: response.data,
        }));
      } catch (error) {
        console.error('Error fetching inventory:', error);
        setError('Error fetching inventory: ' + error.message);
      }
    }
  }, []);

  // Fetch the webhook URL for a specific company
  const fetchWebhookUrl = useCallback(async (companyId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `https://neonbs.org/api/companies/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setWebhookUrl(response.data.webhookUrl || '');
    } catch (error) {
      console.error('Error fetching webhook URL:', error);
      setError('Error fetching webhook URL: ' + error.message);
    }
  }, []);

  // Update the inventory and webhook URL when the selected company changes
  useEffect(() => {
    if (selectedCompany) {
      fetchInventory(selectedCompany);
      fetchWebhookUrl(selectedCompany);
    }
  }, [selectedCompany, fetchInventory, fetchWebhookUrl]);

  // Fetch sales data for a specific company
  const fetchSales = async (companyId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `https://neonbs.org/api/sales/company/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          params: {
            startDate: startDate.toISOString().split('T')[0], // Pass date in YYYY-MM-DD format
            endDate: endDate.toISOString().split('T')[0],
          },
        }
      );
  
      let salesData = response.data;
  
      // Filter sales data if the user is an employee
      if (user.role === 'employee') {
        salesData = salesData.filter((sale) => sale.userId === user.id);
      }
  
      // Calculate total sales, month sales, week sales, and top sales rank
      const totalAmt = salesData.reduce(
        (acc, sale) => acc + parseFloat(sale.price) * sale.quantity,
        0
      );
      const totalQty = salesData.reduce((acc, sale) => acc + sale.quantity, 0);
  
      const currentMonth = new Date().getMonth();
      const monthSalesData = salesData.filter(
        (sale) => new Date(sale.createdAt).getMonth() === currentMonth
      );
      const monthAmt = monthSalesData.reduce(
        (acc, sale) => acc + parseFloat(sale.price) * sale.quantity,
        0
      );
      const monthQty = monthSalesData.reduce(
        (acc, sale) => acc + sale.quantity,
        0
      );
  
      const currentWeekStart = new Date();
      currentWeekStart.setDate(currentWeekStart.getDate() - currentWeekStart.getDay());
      const currentWeekEnd = new Date(currentWeekStart);
      currentWeekEnd.setDate(currentWeekEnd.getDate() + 6);
      const weekSalesData = salesData.filter((sale) => {
        const saleDate = new Date(sale.createdAt);
        return saleDate >= currentWeekStart && saleDate <= currentWeekEnd;
      });
      const weekAmt = weekSalesData.reduce(
        (acc, sale) => acc + parseFloat(sale.price) * sale.quantity,
        0
      );
      const weekQty = weekSalesData.reduce(
        (acc, sale) => acc + sale.quantity,
        0
      );
  
      const userSalesMap = salesData.reduce((acc, sale) => {
        if (!acc[sale.userId]) {
          acc[sale.userId] = { username: sale.User.username, totalSales: 0 };
        }
        acc[sale.userId].totalSales +=
          parseFloat(sale.price) * sale.quantity;
        return acc;
      }, {});
  
      const topSalesRank = Object.values(userSalesMap).sort(
        (a, b) => b.totalSales - a.totalSales
      );
  
      // Update state with the calculated data
      setSales((prevSales) => ({
        ...prevSales,
        [companyId]: salesData,
      }));
      setTotalAmount((prevAmount) => ({
        ...prevAmount,
        [companyId]: totalAmt,
      }));
      setTotalQuantity((prevQuantity) => ({
        ...prevQuantity,
        [companyId]: totalQty,
      }));
      setMonthSales((prevAmount) => ({
        ...prevAmount,
        [companyId]: monthAmt,
      }));
      setMonthQuantity((prevQuantity) => ({
        ...prevQuantity,
        [companyId]: monthQty,
      }));
      setWeekSales((prevAmount) => ({
        ...prevAmount,
        [companyId]: weekAmt,
      }));
      setWeekQuantity((prevQuantity) => ({
        ...prevQuantity,
        [companyId]: weekQty,
      }));
      setTopSalesRank((prevRank) => ({
        ...prevRank,
        [companyId]: topSalesRank,
      }));
    } catch (error) {
      console.error('Error fetching sales:', error);
      setError('Error fetching sales: ' + error.message);
    }
  };
  

  // Handle adding a new company
  const handleAddCompany = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    try {
      await axios.post(
        'https://neonbs.org/api/companies',
        { companyName, ownerId: selectedOwner },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setCompanyName('');
      setSelectedOwner('');
      fetchCompanies(); // Refresh data after adding a new company
    } catch (error) {
      console.error('Error adding company:', error);
      setError('Error adding company: ' + error.message);
    }
  };

  // Handle adding or editing an inventory item
  const handleAddInventoryItem = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const formData = new FormData();

    if (image) {
      // Check if the image size exceeds 2 MB
      if (image.size > 2 * 1024 * 1024) {
        setError('File size exceeds 2 MB limit.');
        return;
      }
      formData.append('image', image);
    }

    formData.append('companyId', selectedCompany);
    formData.append('itemName', itemName);
    formData.append('itemPrice', itemPrice);
    formData.append('itemQuantity', itemQuantity);

    try {
      if (editingItemId) {
        await axios.put(
          `https://neonbs.org/api/inventory/${editingItemId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        setEditingItemId(null);
      } else {
        await axios.post('https://neonbs.org/api/inventory', formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      alert('Inventory item saved successfully');
      setItemName('');
      setItemPrice('');
      setItemQuantity('');
      setImage(null);
      fetchInventory(selectedCompany); // Refresh inventory data after adding an item
    } catch (error) {
      console.error('Error saving inventory item:', error);
      setError('Error saving inventory item: ' + error.message);
    }
  };

  // Handle deleting an inventory item
  const handleDeleteInventoryItem = async (id, companyId) => {
    const token = localStorage.getItem('token');

    try {
      await axios.delete(`https://neonbs.org/api/inventory/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setInventory((prevInventory) => ({
        ...prevInventory,
        [companyId]: prevInventory[companyId].filter((item) => item.id !== id),
      }));
    } catch (error) {
      console.error('Error deleting inventory item:', error);
      setError('Error deleting inventory item: ' + error.message);
    }
  };

  // Handle editing an inventory item
  const handleEditInventoryItem = (item) => {
    setEditingItemId(item.id);
    setSelectedCompany(item.companyId);
    setItemName(item.itemName);
    setItemPrice(item.itemPrice);
    setItemQuantity(item.itemQuantity);
    setImage(null); // Reset image, assume user may want to upload a new one
    setIsAddItemOpen(true); // Open the form when editing
  };

  // Handle tab selection and fetch relevant data
  const handleTabSelect = async (key) => {
    setKey(key);
    if (key !== 'adminPanel') {
      setSelectedCompany(key);
      await fetchSales(key);
      await fetchInventory(key);
      await fetchWebhookUrl(key);
    }
  };

  // Handle saving the webhook URL for a company
  const handleSaveWebhook = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    try {
      await axios.put(
        `https://neonbs.org/api/companies/${selectedCompany}/webhook`,
        { webhookUrl },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setWebhookUrlSaved(true);
      setTimeout(() => setWebhookUrlSaved(false), 3000); // Hide the success message after 3 seconds
    } catch (error) {
      console.error('Error saving webhook URL:', error);
      setError('Error saving webhook URL: ' + error.message);
    }
  };

  // Toggle the visibility of the webhook URL
  const toggleShowWebhook = () => {
    setShowWebhook(!showWebhook);
  };

  // Handle company deletion
  const handleDeleteCompany = async (companyId) => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.delete(
        `https://neonbs.org/api/companies/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      alert(response.data.message);
      setCompanies((prevCompanies) =>
        prevCompanies.filter((company) => company.id !== companyId)
      );
    } catch (error) {
      console.error('Error deleting company:', error);
      setError('Error deleting company: ' + error.message);
    }
  };

  if (loading || !user) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  return (
    <Container className={`mt-5 ${theme}`}>
      <h1 className="text-center mb-4">
        {editingItemId ? 'Edit Inventory Item' : 'Company Dashboard'}
      </h1>
      {error && <Alert variant="danger">{error}</Alert>}
      <Tabs activeKey={key} onSelect={(k) => handleTabSelect(k)} className="mb-3">
        {user.role === 'admin' && (
          <Tab eventKey="adminPanel" title="Admin Panel">
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>Add New Company</Card.Title>
                <Form onSubmit={handleAddCompany}>
                  <Form.Group className="mb-3" controlId="formCompanyName">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter company name"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formOwner">
                    <Form.Label>Select Owner</Form.Label>
                    <Form.Control
                      as="select"
                      value={selectedOwner}
                      onChange={(e) => setSelectedOwner(e.target.value)}
                      required
                    >
                      <option value="">Select Owner</option>
                      {users.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.username}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Add Company
                  </Button>
                </Form>
              </Card.Body>
            </Card>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>Assign Employees to Companies</Card.Title>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>User Name</th>
                      {companies.map((company) => (
                        <th key={company.id}>{company.companyName}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user) => (
                      <tr key={user.id}>
                        <td>
                          {user.role === 'admin'
                            ? `${user.cityName} (Admin)`
                            : user.cityName}
                        </td>
                        {companies.map((company) => (
                          <td key={company.id}>
                            <Form.Check
                              type="checkbox"
                              label="Employee"
                              checked={company.employees.some(
                                (emp) =>
                                  emp.id === user.id && emp.role === 'employee'
                              )}
                              onChange={(e) =>
                                handleRoleChange(
                                  user.id,
                                  company.id,
                                  'employee',
                                  e.target.checked
                                )
                              }
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Tab>
        )}
        {companies.map((company) => (
          <Tab key={company.id} eventKey={company.id} title={company.companyName}>
            <Tabs defaultActiveKey="sales" className="mb-3">
              <Tab eventKey="sales" title="Sales">
                <Card className="mb-4">
                  <Card.Body>
                    <Card.Title>{company.companyName} Sales</Card.Title>
                    <div className="controls-row">
                      <div className="control-group">
                        <label>Select Date Range</label>
                        <div className="date-picker-container">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            className="date-picker"
                          />
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            className="date-picker"
                          />
                        </div>
                      </div>
                      <div className="control-group">
                        <button
                          onClick={() => fetchSales(company.id)}
                          className="fetch-button"
                        >
                          Fetch Data
                        </button>
                      </div>
                    </div>
                    <div className="row mt-4">
                      {/* Month Sales Section */}
                      <div className="col-md-6 mb-4">
                        <div className="border p-3 rounded">
                          <h5>Month Sales</h5>
                          <p>
                            <strong>Total Amount Sold This Month:</strong> $
                            {monthSales[company.id]?.toFixed(2) || 0}
                          </p>
                          <p>
                            <strong>Total Quantity Sold This Month:</strong>{' '}
                            {monthQuantity[company.id] || 0}
                          </p>
                        </div>
                      </div>

                      {/* Week Sales Section */}
                      <div className="col-md-6 mb-4">
                        <div className="border p-3 rounded">
                          <h5>Week Sales</h5>
                          <p>
                            <strong>Total Amount Sold This Week:</strong> $
                            {weekSales[company.id]?.toFixed(2) || 0}
                          </p>
                          <p>
                            <strong>Total Quantity Sold This Week:</strong>{' '}
                            {weekQuantity[company.id] || 0}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* Top Sales Rank Section */}
                    <div className="row">
                      <div className="col-12">
                        <div className="border p-3 rounded">
                          <h5>Top Sales Rank by User</h5>
                          <ul className="list-group">
                            {(topSalesRank[company.id] || []).map(
                              (user, index) => (
                                <li
                                  key={user.userId}
                                  className="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  <span>
                                    <strong>#{index + 1}</strong>{' '}
                                    {user.username}
                                  </span>
                                  <span>${user.totalSales.toFixed(2)}</span>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>

                    {/* Sales Details Section */}
                    <div className="sales-container mt-4">
                      <h5>Sales</h5>
                      <ul className="list-group">
                        {(sales[company.id] || []).map((sale) => (
                          <li key={sale.id} className="list-group-item">
                            <div className="sale-details">
                              <span>
                                <strong>Item:</strong> {sale.itemName}
                              </span>
                              <span>
                                <strong>Price:</strong> $
                                {parseFloat(sale.price).toFixed(2)}
                              </span>
                              <span>
                                <strong>Quantity:</strong> {sale.quantity}
                              </span>
                              <span>
                                <strong>Total:</strong> $
                                {(parseFloat(sale.price) * sale.quantity).toFixed(
                                  2
                                )}
                              </span>
                              <span>
                                <strong>Sold by:</strong> {sale.User.username}
                              </span>
                              <span>
                                <strong>Date:</strong>{' '}
                                {new Date(sale.createdAt).toLocaleDateString()}{' '}
                                <strong>Time:</strong>{' '}
                                {new Date(sale.createdAt).toLocaleTimeString()}
                              </span>
                            </div>
                            {user.role === 'admin' && (
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() =>
                                  handleDeleteSale(sale.id, company.id)
                                }
                              >
                                Delete
                              </Button>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
              </Tab>
              {(user.role === 'admin' ||
                (user.role === 'boss' &&
                  (company.ownerId === user.id ||
                    company.employees.some(
                      (employee) => employee.id === user.id
                    )))) && (
                <Tab eventKey="addInventory" title="Add Inventory">
                  <Card className="mb-4" border="secondary">
                    <Card.Header>
                      <Button
                        variant="link"
                        onClick={() => setIsAddItemOpen(!isAddItemOpen)}
                        aria-controls="add-item-form"
                        aria-expanded={isAddItemOpen}
                      >
                        {isAddItemOpen ? <FaAngleUp /> : <FaAngleDown />}{' '}
                        {isAddItemOpen ? 'Hide' : 'Add'} Item Form
                      </Button>
                    </Card.Header>
                    <Collapse in={isAddItemOpen}>
                      <div id="add-item-form">
                        <Card.Body>
                          <Form onSubmit={handleAddInventoryItem}>
                            <Form.Group className="mb-3">
                              <Form.Label>Item Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Item Name"
                                value={itemName}
                                onChange={(e) => setItemName(e.target.value)}
                                required
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Item Price</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Item Price"
                                value={itemPrice}
                                onChange={(e) => setItemPrice(e.target.value)}
                                required
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Item Quantity</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Item Quantity"
                                value={itemQuantity}
                                onChange={(e) => setItemQuantity(e.target.value)}
                                required
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Item Image</Form.Label>
                              <Form.Control
                                type="file"
                                onChange={(e) => setImage(e.target.files[0])}
                              />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                              {editingItemId ? 'Update Item' : 'Add Item'}
                            </Button>
                          </Form>
                        </Card.Body>
                      </div>
                    </Collapse>
                  </Card>
                  <Card border="primary">
                    <Card.Header>
                      <h2 className="text-center">Inventory Items</h2>
                    </Card.Header>
                    <Card.Body>
                      <div className="inventory-container">
                        <Row className="flex-wrap">
                          {(inventory[selectedCompany] || []).map((item) => (
                            <Col md={3} key={item.id} className="mb-3">
                              <Card>
                                <Card.Body>
                                  <h3>{item.itemName}</h3>
                                  <p>Price: ${item.itemPrice}</p>
                                  <p>Amount in stock: {item.itemQuantity}</p>
                                  {item.imageUrl && (
                                    <img
                                      src={`https://neonbs.org/${item.imageUrl}`}
                                      alt={item.itemName}
                                      className="img-thumbnail inventory-image"
                                    />
                                  )}
                                  <Button
                                    variant="danger"
                                    onClick={() =>
                                      handleDeleteInventoryItem(
                                        item.id,
                                        selectedCompany
                                      )
                                    }
                                    className="mt-3"
                                  >
                                    Delete
                                  </Button>
                                  <Button
                                    variant="warning"
                                    onClick={() => handleEditInventoryItem(item)}
                                    className="mt-3"
                                  >
                                    Edit
                                  </Button>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </Card.Body>
                  </Card>
                </Tab>
              )}

              {user.role === 'admin' && (
                <Tab eventKey="settings" title={<FaCog />}>
                  <Card className="mb-4">
                    <Card.Body>
                      <Card.Title>{company.companyName} Settings</Card.Title>
                      <Button
                        variant="danger"
                        onClick={() => handleDeleteCompany(company.id)}
                        className="mb-3"
                      >
                        Delete Company
                      </Button>
                      <Form onSubmit={handleSaveWebhook}>
                        <Form.Group className="mb-3">
                          <Form.Label>Discord Webhook URL</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type={showWebhook ? 'text' : 'password'}
                              placeholder="Enter Discord Webhook URL"
                              value={webhookUrl}
                              onChange={(e) => setWebhookUrl(e.target.value)}
                              required
                            />
                            <Button
                              variant="outline-secondary"
                              onClick={toggleShowWebhook}
                            >
                              {showWebhook ? <FaEyeSlash /> : <FaEye />}
                            </Button>
                          </InputGroup>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                          Save Webhook URL
                        </Button>
                        {webhookUrlSaved && (
                          <Alert variant="success" className="mt-3">
                            Webhook URL saved successfully!
                          </Alert>
                        )}
                      </Form>
                    </Card.Body>
                  </Card>
                </Tab>
              )}
            </Tabs>
          </Tab>
        ))}
      </Tabs>
    </Container>
  );
};

export default withAuth(ManageCompanies);
