import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaMoon, FaSun, FaSignOutAlt, FaUserCircle, FaSignInAlt } from 'react-icons/fa';
import '../styles.css';

const Header = ({ toggleTheme, theme }) => {
  const { user, logout } = useContext(AuthContext);
  const [userinfo, setUserinfo] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchUserInfo = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('User is not authenticated');
        return;
      }

      try {
        const response = await axios.get('https://neonbs.org/api/auth/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserinfo(response.data);
      } catch (error) {
        console.error('Error fetching user info:', error);
        setError(error.response?.data?.message || 'Error fetching user info');
        if (error.response?.status === 401) {
          logout();
          navigate('/login');
        }
      }
    };

    // Fetch user info only if user is authenticated and not on specific routes
    if (user) {
      fetchUserInfo();
    } else if (!['/register', '/terms', '/password-reset', '/login', '/reset-password'].includes(location.pathname)) {
      navigate('/login');
    } else if (location.pathname === '/reset-password') {
      // Handle case for reset password where no redirection to login is needed
      setError(''); // Clear any authentication errors
    }
  }, [user, logout, navigate, location.pathname]);

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  return (
    <header className={`header ${theme}`}>
      <h2>Dashboard</h2>
      <div className="settings">
        <button onClick={toggleTheme}>
          {theme === 'light' ? <FaMoon /> : <FaSun />}
        </button>
        {user ? (
          <div className="user">
            <FaUserCircle />
            <span>{userinfo?.username}</span>
            <button onClick={logout}>
              <FaSignOutAlt /> Logout
            </button>
          </div>
        ) : (
          <button onClick={handleLoginRedirect}>
            <FaSignInAlt /> Login
          </button>
        )}
        {error && <div className="error">{error}</div>}
      </div>
    </header>
  );
};

export default Header;
