import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import { ThemeContext } from '../contexts/ThemeContext';
import { Container, Form, Button, Card, ListGroup, Alert, Spinner } from 'react-bootstrap';
import '../styles.css';
import withAuth from '../hoc/withAuth';

const AddInventory = () => {
  const { theme } = useContext(ThemeContext);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [itemName, setItemName] = useState('');
  const [itemPrice, setItemPrice] = useState('');
  const [itemQuantity, setItemQuantity] = useState('');
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [inventory, setInventory] = useState([]);
  const [editingItemId, setEditingItemId] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://neonbs.org/api/companies/user', {
          headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        });
        setCompanies(response.data);
      } catch (error) {
        console.error('Error fetching companies:', error);
        setError('Error fetching companies');
      } finally {
        setLoading(false);
      }
    };

    fetchCompanies();
  }, []);

  const fetchInventory = useCallback(async () => {
    if (selectedCompany) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://neonbs.org/api/inventory/company/${selectedCompany}`, {
          headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        });
        setInventory(response.data);
      } catch (error) {
        console.error('Error fetching inventory:', error);
        setError('Error fetching inventory');
      }
    }
  }, [selectedCompany]);

  useEffect(() => {
    fetchInventory();
  }, [selectedCompany, fetchInventory]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('companyId', selectedCompany);
    formData.append('itemName', itemName);
    formData.append('itemPrice', itemPrice);
    formData.append('itemQuantity', itemQuantity);
    if (image) formData.append('image', image);

    try {
      if (editingItemId) {
        // Update existing item
        await axios.put(`https://neonbs.org/api/inventory/${editingItemId}`, formData, {
          headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
        });
        setEditingItemId(null);
      } else {
        // Add new item
        await axios.post('https://neonbs.org/api/inventory', formData, {
          headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
        });
      }

      alert('Inventory item saved successfully');
      setItemName('');
      setItemPrice('');
      setItemQuantity('');
      setImage(null);
      setImageUrl('');
      fetchInventory();
    } catch (error) {
      console.error('Error saving inventory item:', error);
      setError('Error saving inventory item');
    }
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem('token');

    try {
      await axios.delete(`https://neonbs.org/api/inventory/${id}`, {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
      });
      setInventory(inventory.filter((item) => item.id !== id));
    } catch (error) {
      console.error('Error deleting inventory item:', error);
      setError('Error deleting inventory item');
    }
  };

  const handleEdit = (item) => {
    setEditingItemId(item.id);
    setSelectedCompany(item.companyId);
    setItemName(item.itemName);
    setItemPrice(item.itemPrice);
    setItemQuantity(item.itemQuantity);
    setImageUrl(item.imageUrl); // Set current image URL
    setImage(null); // Reset image, assume user may want to upload a new one
  };

  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  return (
    <Container className={`mt-5 ${theme}`}>
      <h1 className="mb-4">{editingItemId ? 'Edit Inventory Item' : 'Add Inventory Item'}</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      <Card className="mb-4">
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Select Company</Form.Label>
              <Form.Control as="select" value={selectedCompany} onChange={(e) => setSelectedCompany(e.target.value)} required>
                <option value="" disabled>
                  Select a company
                </option>
                {companies.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.companyName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Item Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Item Name"
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Item Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Item Price"
                value={itemPrice}
                onChange={(e) => setItemPrice(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Item Quantity</Form.Label>
              <Form.Control
                type="number"
                placeholder="Item Quantity"
                value={itemQuantity}
                onChange={(e) => setItemQuantity(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Item Image</Form.Label>
              {imageUrl && (
                <div className="mb-3">
                  <img
                    src={`https://neonbs.org/${imageUrl}`}
                    alt={itemName}
                    className="img-thumbnail"
                    style={{ maxWidth: '150px' }}
                  />
                </div>
              )}
              <Form.File
                id="custom-file"
                label={image ? image.name : editingItemId ? itemName : 'Choose file'}
                custom
                onChange={(e) => setImage(e.target.files[0])}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              {editingItemId ? 'Update Item' : 'Add Item'}
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <h2 className="mb-4">Inventory Items</h2>
      <ListGroup>
        {inventory.map((item) => (
          <ListGroup.Item key={item.id} className="mb-3">
            <Card>
              <Card.Body>
                <h3>{item.itemName}</h3>
                <p>Price: ${item.itemPrice}</p>
                <p>Amount in stock: {item.itemQuantity}</p>
                {item.imageUrl && (
                  <img
                    src={`https://neonbs.org/${item.imageUrl}`}
                    alt={item.itemName}
                    className="img-thumbnail"
                  />
                )}
                <Button variant="danger" onClick={() => handleDelete(item.id)} className="mt-3">
                  Delete
                </Button>
                <Button variant="warning" onClick={() => handleEdit(item)} className="mt-3">
                  Edit
                </Button>
              </Card.Body>
            </Card>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  );
};

export default withAuth(AddInventory);
