import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Card, Button, Spinner, Alert } from 'react-bootstrap';
import withAuth from '../hoc/withAuth';

const JobApplication = () => {
  const [companies, setCompanies] = useState([]);
  const [applications, setApplications] = useState([]);
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const clearError = () => setError('');

  // Fetch the authenticated user
  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('User is not authenticated');
        setLoading(false);
        return;
      }
      try {
        const response = await axios.get('https://neonbs.org/api/auth/me', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user:', error);
        setError('Error fetching user');
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  // Fetch available companies (jobs)
  const fetchCompanies = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('No token found. Please log in again.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get('https://neonbs.org/api/companies/available', {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      const acceptingCompanies = response.data.filter(company => company.acceptingApplications);
      setCompanies(acceptingCompanies);

      if (acceptingCompanies.length === 0) {
        setError('No companies available for application.');
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
      setError('Error fetching companies');
    } finally {
      setLoading(false);
    }
  };

  // Fetch user's applications
  const fetchUserApplications = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get('https://neonbs.org/api/companyApplications/me', {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setApplications(response.data);
    } catch (error) {
      console.error('Error fetching user applications:', error);
      setError('Error fetching applications');
    }
  };
  const unapplyFromApplication = async (applicationId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found, user might not be authenticated');
      setError('You need to be logged in to withdraw an application.');
      return;
    }
  
    try {
      await axios.put(`https://neonbs.org/api/companyApplications/${applicationId}/withdraw`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      fetchUserApplications(); // Refresh the applications list after withdrawing
    } catch (error) {
      console.error(`Error withdrawing application ${applicationId}:`, error);
      if (error.response && error.response.data && error.response.data.error) {
        setError(`Error withdrawing application: ${error.response.data.error}`);
      } else {
        setError(`An unexpected error occurred while withdrawing application ${applicationId}`);
      }
    }
  };
  
  // Handle application to a company
const applyToCompany = async (applicationId) => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error('No token found, user might not be authenticated');
    setError('You need to be logged in to apply to a company.');
    return;
  }

  try {
    await axios.post('https://neonbs.org/api/companies/apply', { applicationId }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    fetchUserApplications(); // Refresh the applications list after applying
  } catch (error) {
    console.error(`Error applying to company ${applicationId}:`, error);
    if (error.response && error.response.data && error.response.data.error) {
      setError(`Error applying to company: ${error.response.data.error}`);
    } else {
      setError(`An unexpected error occurred while applying to company ${applicationId}`);
    }
  }
};


  // Handle withdrawing application from a company
  const unapplyFromCompany = async (companyId) => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`https://neonbs.org/api/companyApplications/${companyId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchUserApplications(); // Refresh the applications list after withdrawing
    } catch (error) {
      console.error(`Error withdrawing application from company ${companyId}:`, error);
      setError(`Error withdrawing application from company ${companyId}`);
    }
  };

  useEffect(() => {
    if (user) {
      fetchCompanies();
      fetchUserApplications();
    }
  }, [user]);

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <Card className="job-application-card">
      <Card.Body>
        <Card.Title>Available Jobs</Card.Title>
        {error && <Alert variant="danger">{error}</Alert>}
        {companies.length === 0 ? (
          <Alert variant="info">No companies are currently accepting applications.</Alert>
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {companies.map((company) => {
                const application = applications.find(app => app.companyId === company.id);
                const statusLabel = !application || application.status === 'not_applied'
                  ? 'Not Applied'
                  : application.status === 'approved'
                  ? 'Applied (Accepted)'
                  : application.status === 'rejected'
                  ? 'Applied (Denied)'
                  : 'Applied (Pending)';

                return (
                  <tr key={company.id}>
                    <td>{company.companyName}</td>
                    <td>{statusLabel}</td>
                    <td>
                      {!application || application.status === 'not_applied' ? (
                        <Button
                          variant="primary"
                          onClick={() => applyToCompany(application.id)}
                        >
                          Apply
                        </Button>
                      ) : (
                        application.status === 'approved' && (
                          <Button
                            variant="danger"
                            onClick={() => unapplyFromApplication(application.id)}
                          >
                            Unapply
                          </Button>
                        )
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </Card.Body>
    </Card>
  );
};

export default withAuth(JobApplication);
