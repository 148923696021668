import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../contexts/ThemeContext';
import '../styles.css';

const Register = () => {
  const { theme } = useContext(ThemeContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [cityName, setCityName] = useState(''); // State for city name
  const [role] = useState('employee');
  const [passwordStrength, setPasswordStrength] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const agreed = localStorage.getItem('agreedToTerms');
    if (agreed === 'true') {
      setAgreeToTerms(true);
      localStorage.removeItem('agreedToTerms'); // Clear the value after use
    }
  }, []);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    setPasswordStrength({
      length: newPassword.length >= 8,
      uppercase: /[A-Z]/.test(newPassword),
      lowercase: /[a-z]/.test(newPassword),
      number: /\d/.test(newPassword),
      specialChar: /[\W_]/.test(newPassword),
    });
  };

  const calculateStrength = () => {
    const metRequirements = Object.values(passwordStrength).filter(Boolean).length;
    return (metRequirements / Object.keys(passwordStrength).length) * 100;
  };

  const isPasswordStrong = () => {
    return Object.values(passwordStrength).every(Boolean);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isPasswordStrong()) {
      alert('Password does not meet all requirements.');
      return;
    }

    if (!agreeToTerms) {
      alert('You must agree to the terms and conditions.');
      return;
    }

    try {
      await axios.post('https://neonbs.org/api/auth/register', { username, password, cityName, role });
      alert('Registration successful. Please login.');
      navigate('/login');
    } catch (error) {
      console.error('Error registering:', error);
      alert(`Registration failed: ${error.response ? error.response.data.error : error.message}`);
    }
  };

  return (
    <div className={`form-container ${theme}`}>
      <form onSubmit={handleSubmit} className="register-form">
        <h2 className="form-title">Register</h2>
        <div className="input-group">
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="form-input"
          />
        </div>
        <div className="input-group">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
            required
            className="form-input"
          />
        </div>
        <div className="strength-bar-container">
          <div
            className="strength-bar"
            style={{
              width: `${calculateStrength()}%`,
              backgroundColor: calculateStrength() === 100 ? '#4CAF50' : '#FF5722',
            }}
          ></div>
        </div>
        <ul className="password-requirements">
          <li className={passwordStrength.length ? 'met' : 'not-met'}>
            {passwordStrength.length ? '✔️' : '❌'} At least 8 characters
          </li>
          <li className={passwordStrength.uppercase ? 'met' : 'not-met'}>
            {passwordStrength.uppercase ? '✔️' : '❌'} At least one uppercase letter
          </li>
          <li className={passwordStrength.lowercase ? 'met' : 'not-met'}>
            {passwordStrength.lowercase ? '✔️' : '❌'} At least one lowercase letter
          </li>
          <li className={passwordStrength.number ? 'met' : 'not-met'}>
            {passwordStrength.number ? '✔️' : '❌'} At least one number
          </li>
          <li className={passwordStrength.specialChar ? 'met' : 'not-met'}>
            {passwordStrength.specialChar ? '✔️' : '❌'} At least one special character
          </li>
        </ul>
        <div className="input-group">
          <input
            type="text"
            placeholder="City Name"
            value={cityName}
            onChange={(e) => setCityName(e.target.value)}
            required
            className="form-input"
          />
        </div>
        <div className="input-group">
          <label>
            <input
              type="checkbox"
              checked={agreeToTerms}
              onChange={(e) => setAgreeToTerms(e.target.checked)}
            />
            &nbsp;I agree to the <a href="/terms">terms and conditions</a>
          </label>
        </div>
        <button type="submit" disabled={!isPasswordStrong() || !agreeToTerms} className="form-button">
          Register
        </button>
      </form>
    </div>
  );
};

export default Register;
