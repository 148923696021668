import React from 'react';
import { Card, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

const CompanyDetails = ({ company, startDate, setStartDate, endDate, setEndDate, fetchSales, sales, monthSales, monthQuantity, weekSales, weekQuantity, topSalesRank, handleDeleteSale, user }) => {
  return (
    <Card className="mb-4">
      <Card.Body>
        <Card.Title>{company.companyName} Sales</Card.Title>
        <div className="controls-row">
          <div className="control-group">
            <label>Select Date Range</label>
            <div className="date-picker-container">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                className="date-picker"
              />
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                className="date-picker"
              />
            </div>
          </div>
          <div className="control-group">
            <Button onClick={() => fetchSales(company.id)} className="fetch-button">
              Fetch Data
            </Button>
          </div>
        </div>

        <div className="row mt-4">
          {/* Month Sales Section */}
          <div className="col-md-6 mb-4">
            <div className="border p-3 rounded">
              <h5>Month Sales</h5>
              <p>
                <strong>Total Amount Sold This Month:</strong> ${monthSales[company.id]?.toFixed(2) || 0}
              </p>
              <p>
                <strong>Total Quantity Sold This Month:</strong> {monthQuantity[company.id] || 0}
              </p>
            </div>
          </div>

          {/* Week Sales Section */}
          <div className="col-md-6 mb-4">
            <div className="border p-3 rounded">
              <h5>Week Sales</h5>
              <p>
                <strong>Total Amount Sold This Week:</strong> ${weekSales[company.id]?.toFixed(2) || 0}
              </p>
              <p>
                <strong>Total Quantity Sold This Week:</strong> {weekQuantity[company.id] || 0}
              </p>
            </div>
          </div>
        </div>

        {/* Top Sales Rank Section */}
        <div className="row">
          <div className="col-12">
            <div className="border p-3 rounded">
              <h5>Top Sales Rank by User</h5>
              <ul className="list-group">
                {(topSalesRank[company.id] || []).map((user, index) => (
                  <li key={user.userId} className="list-group-item d-flex justify-content-between align-items-center">
                    <span>
                      <strong>#{index + 1}</strong> {user.username}
                    </span>
                    <span>${user.totalSales.toFixed(2)}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Sales Details Section */}
        <div className="sales-container mt-4">
          <h5>Sales</h5>
          <ul className="list-group">
            {(sales[company.id] || []).map((sale) => (
              <li key={sale.id} className="list-group-item">
                <div className="sale-details">
                  <span>
                    <strong>Item:</strong> {sale.itemName}
                  </span>
                  <span>
                    <strong>Price:</strong> ${parseFloat(sale.price).toFixed(2)}
                  </span>
                  <span>
                    <strong>Quantity:</strong> {sale.quantity}
                  </span>
                  <span>
                    <strong>Total:</strong> ${(parseFloat(sale.price) * sale.quantity).toFixed(2)}
                  </span>
                  <span>
                    <strong>Sold by:</strong> {sale.User.username}
                  </span>
                  <span>
                    <strong>Date:</strong> {new Date(sale.createdAt).toLocaleDateString()} <strong>Time:</strong> {new Date(sale.createdAt).toLocaleTimeString()}
                  </span>
                </div>
                {user.role === 'admin' && (
                  <Button variant="danger" size="sm" onClick={() => handleDeleteSale(sale.id, company.id)}>
                    Delete
                  </Button>
                )}
              </li>
            ))}
          </ul>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CompanyDetails;
