import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Card, Row, Col, Spinner } from 'react-bootstrap';
import '../styles.css';

const PasswordResetRequest = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);  // Loading state to show spinner

  const handlePasswordResetRequest = async (e) => {
    e.preventDefault();
    setLoading(true);  // Set loading to true when starting the request
    setMessage('');  // Clear any previous messages

    try {
      const response = await axios.post(`https://neonbs.org/api/auth/request-password-reset`, { email });
      setMessage(response.data.message || 'If the email address exists in our system, a reset link has been sent.');
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        if (error.response.status === 404) {
          setMessage('Email address not found.');
        } else if (error.response.status === 400) {
          setMessage('Invalid email address. Please enter a valid email.');
        } else if (error.response.status === 500) {
          setMessage('A server error occurred. Please try again later.');
        } else {
          setMessage(error.response.data.error || 'An unknown error occurred. Please try again.' + email);
        }
      } else if (error.request) {
        // Request was made but no response was received
        setMessage('No response from the server. Please check your internet connection and try again.');
      } else {
        // Something else caused the error
        setMessage(`An error occurred: ${error.message}`);
      }
    } finally {
      setLoading(false);  // Stop loading when the request is complete
      setEmail('');  // Clear the email input field
    }
  };

  return (
    <Container className="neon-background-image">
      <Row className="w-100">
        <Col md={{ span: 6, offset: 3 }}>
          <Card className="p-4 neon-card">
            <Card.Body>
              <h2 className="text-center mb-4 neon-text">Request Password Reset</h2>
              <Form onSubmit={handlePasswordResetRequest}>
                <Form.Group controlId="email" className="mb-3">
                  <Form.Label className="neon-text">Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="neon-input"
                    disabled={loading}  // Disable input while loading
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100 neon-button" disabled={loading}>
                  {loading ? <Spinner animation="border" size="sm" /> : 'Send Reset Link'}
                </Button>
              </Form>
              {message && <p className="text-center mt-3 neon-text">{message}</p>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordResetRequest;
