import React from 'react';
import { Card, Form, Button, Collapse, Row, Col } from 'react-bootstrap';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';

const InventoryManagement = ({ inventory, selectedCompany, itemName, setItemName, itemPrice, setItemPrice, itemQuantity, setItemQuantity, image, setImage, handleAddInventoryItem, isAddItemOpen, setIsAddItemOpen, handleDeleteInventoryItem, handleEditInventoryItem, editingItemId }) => {
  return (
    <div>
      <Card className="mb-4" border="secondary">
        <Card.Header>
          <Button variant="link" onClick={() => setIsAddItemOpen(!isAddItemOpen)} aria-controls="add-item-form" aria-expanded={isAddItemOpen}>
            {isAddItemOpen ? <FaAngleUp /> : <FaAngleDown />} {isAddItemOpen ? 'Hide' : 'Add'} Item Form
          </Button>
        </Card.Header>
        <Collapse in={isAddItemOpen}>
          <div id="add-item-form">
            <Card.Body>
              <Form onSubmit={handleAddInventoryItem}>
                <Form.Group className="mb-3">
                  <Form.Label>Item Name</Form.Label>
                  <Form.Control type="text" placeholder="Item Name" value={itemName} onChange={(e) => setItemName(e.target.value)} required />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Item Price</Form.Label>
                  <Form.Control type="number" placeholder="Item Price" value={itemPrice} onChange={(e) => setItemPrice(e.target.value)} required />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Item Quantity</Form.Label>
                  <Form.Control type="number" placeholder="Item Quantity" value={itemQuantity} onChange={(e) => setItemQuantity(e.target.value)} required />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Item Image</Form.Label>
                  <Form.Control type="file" onChange={(e) => setImage(e.target.files[0])} />
                </Form.Group>
                <Button variant="primary" type="submit">
                  {editingItemId ? 'Update Item' : 'Add Item'}
                </Button>
              </Form>
            </Card.Body>
          </div>
        </Collapse>
      </Card>

      <Card border="primary">
        <Card.Header>
          <h2 className="text-center">Inventory Items</h2>
        </Card.Header>
        <Card.Body>
          <div className="inventory-container">
            <Row className="flex-wrap">
              {(inventory[selectedCompany] || []).map((item) => (
                <Col md={3} key={item.id} className="mb-3">
                  <Card>
                    <Card.Body>
                      <h3>{item.itemName}</h3>
                      <p>Price: ${item.itemPrice}</p>
                      <p>Amount in stock: {item.itemQuantity}</p>
                      {item.imageUrl && <img src={`https://neonbs.org/${item.imageUrl}`} alt={item.itemName} className="img-thumbnail inventory-image" />}
                      <Button variant="danger" onClick={() => handleDeleteInventoryItem(item.id, selectedCompany)} className="mt-3">
                        Delete
                      </Button>
                      <Button variant="warning" onClick={() => handleEditInventoryItem(item)} className="mt-3">
                        Edit
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default InventoryManagement;
