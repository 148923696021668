import React from 'react';
import { Card, Form, Button, InputGroup, Alert } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const CompanySettings = ({ company, webhookUrl, setWebhookUrl, showWebhook, toggleShowWebhook, handleSaveWebhook, webhookUrlSaved, handleDeleteCompany }) => {
  return (
    <Card className="mb-4">
      <Card.Body>
        <Card.Title>{company.companyName} Settings</Card.Title>
        <Button variant="danger" onClick={() => handleDeleteCompany(company.id)} className="mb-3">
          Delete Company
        </Button>
        <Form onSubmit={handleSaveWebhook}>
          <Form.Group className="mb-3">
            <Form.Label>Discord Webhook URL</Form.Label>
            <InputGroup>
              <Form.Control
                type={showWebhook ? 'text' : 'password'}
                placeholder="Enter Discord Webhook URL"
                value={webhookUrl}
                onChange={(e) => setWebhookUrl(e.target.value)}
                required
              />
              <Button variant="outline-secondary" onClick={toggleShowWebhook}>
                {showWebhook ? <FaEyeSlash /> : <FaEye />}
              </Button>
            </InputGroup>
          </Form.Group>
          <Button variant="primary" type="submit">
            Save Webhook URL
          </Button>
          {webhookUrlSaved && (
            <Alert variant="success" className="mt-3">
              Webhook URL saved successfully!
            </Alert>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default CompanySettings;
