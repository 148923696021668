import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { Container, Card, Button, ListGroup, Alert, Spinner, Form, Tabs, Tab } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../sales.css';
import withAuth from '../hoc/withAuth';

const Sales = () => {
  const { user } = useAuth();
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [sales, setSales] = useState({});
  const [totalAmount, setTotalAmount] = useState({});
  const [totalQuantity, setTotalQuantity] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [key, setKey] = useState('allCompanies');

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://neonbs.org/api/companies/user', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setCompanies(response.data);
        if (response.data.length > 0) {
          setSelectedCompany(response.data[0].id);
        }
      } catch (error) {
        console.error('Error fetching companies:', error);
        setError('Error fetching companies');
      }
    };
    fetchCompanies();
  }, []);

  const fetchSales = async (companyId) => {
    if (companyId) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://neonbs.org/api/sales/company/${companyId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          params: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        });
        const salesData = response.data;

        // Calculate total amount and total quantity
        const totalAmt = salesData.reduce((acc, sale) => acc + parseFloat(sale.price) * sale.quantity, 0);
        const totalQty = salesData.reduce((acc, sale) => acc + sale.quantity, 0);

        setSales((prevSales) => ({
          ...prevSales,
          [companyId]: salesData,
        }));
        setTotalAmount((prevAmount) => ({
          ...prevAmount,
          [companyId]: totalAmt,
        }));
        setTotalQuantity((prevQuantity) => ({
          ...prevQuantity,
          [companyId]: totalQty,
        }));
      } catch (error) {
        console.error('Error fetching sales:', error.response ? error.response.data : error.message);
        setError('Error fetching sales');
      }
    }
  };

  const handleTabSelect = async (key) => {
    setKey(key);
    if (key !== 'allCompanies') {
      setSelectedCompany(key);
      await fetchSales(key);
    }
  };

  return (
    <Container className="mt-5">
      <h1 className="mb-4">View Sales</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      <Tabs activeKey={key} onSelect={(k) => handleTabSelect(k)} className="mb-3">
        {companies.map((company) => (
          <Tab key={company.id} eventKey={company.id} title={company.companyName}>
            <Card>
              <Card.Body>
                <h2>{company.companyName}</h2>
                <div className="controls-row mb-4">
                  <div className="control-group">
                    <label>Select Date Range</label>
                    <div className="date-picker-container">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        className="date-picker"
                      />
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        className="date-picker"
                      />
                    </div>
                  </div>
                  <div className="control-group">
                    <Button variant="primary" onClick={() => fetchSales(company.id)} className="fetch-button">Fetch Data</Button>
                  </div>
                </div>
                <div className="totals">
                  <h3>Total Sales</h3>
                  <p><strong>Total Amount Sold:</strong> ${totalAmount[company.id]?.toFixed(2) || 0}</p>
                  <p><strong>Total Quantity Sold:</strong> {totalQuantity[company.id] || 0}</p>
                </div>
                <div className="sales-list">
                  <h3>Sales</h3>
                  <ListGroup>
                    {(sales[company.id] || []).map((sale) => (
                      <ListGroup.Item key={sale.id} className="sale-item">
                        <div className="sale-details">
                          <span><strong>Item:</strong> {sale.itemName}</span>
                          <span><strong>Price:</strong> ${parseFloat(sale.price).toFixed(2)}</span>
                          <span><strong>Quantity:</strong> {sale.quantity}</span>
                          <span><strong>Total:</strong> ${(parseFloat(sale.price) * sale.quantity).toFixed(2)}</span>
                          <span><strong>Sold by:</strong> {sale.User.username}</span>
                          <span><strong>Date:</strong> {new Date(sale.createdAt).toLocaleDateString()} <strong>Time:</strong> {new Date(sale.createdAt).toLocaleTimeString()}</span>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </Card.Body>
            </Card>
          </Tab>
        ))}
      </Tabs>
    </Container>
  );
};

export default withAuth(Sales);
