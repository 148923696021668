import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { ThemeContext } from '../contexts/ThemeContext';
import { Container, ListGroup, Button, Alert, Spinner } from 'react-bootstrap';
import '../styles.css';
import withAuth from '../hoc/withAuth';

const AdminApproval = () => {
  const { theme } = useContext(ThemeContext);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('User is not authenticated');
        return;
      }
      try {
        const response = await axios.get('https://neonbs.org/api/users', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsers(response.data);
      } catch (error) {
        setError(error.response?.data?.message || 'Error fetching users');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const approveUser = async (userId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('User is not authenticated');
        return;
      }

      await axios.put(`https://neonbs.org/api/users/${userId}/approve`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers((prevUsers) => 
        prevUsers.filter((user) => user.id !== userId)
      );
    } catch (error) {
      setError(error.response?.data?.message || 'Error approving user');
    }
  };

  const unapprovedUsers = users.filter((user) => !user.approved);

  if (loading) {
    return <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>;
  }

  return (
    <Container className={`mt-5 ${theme}`}>
      <h1>Admin User Approval</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      {unapprovedUsers.length === 0 ? (
        <p>No users to approve.</p>
      ) : (
        <ListGroup>
          {unapprovedUsers.map((user) => (
            <ListGroup.Item key={user.id}>
              {user.username} ({user.role})
              <Button variant="success" onClick={() => approveUser(user.id)} className="ml-2">Approve</Button>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Container>
  );
};

export default withAuth(AdminApproval);
