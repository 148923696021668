import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { FaHome, FaCashRegister, FaUser, FaBars, FaUserTie, FaDesktop, FaBriefcase, FaCpanel, FaHandMiddleFinger } from 'react-icons/fa';
import axios from 'axios';
import '../styles.css';

const Sidebar = () => {
  const { user } = useContext(AuthContext);
  const [isManager, setIsManager] = useState(false);

  useEffect(() => {
    const checkIfManager = async () => {
      if (!user || user.role === 'admin' || user.role === 'boss') return;

      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://neonbs.org/api/companies/user', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const companies = response.data;

        // Check if the user is a manager in any company
        const isUserManager = companies.some((company) =>
          company.employees.some(
            (employee) => employee.id === user.id && employee.UserCompanies.manager
          )
        );

        setIsManager(isUserManager);
      } catch (error) {
        console.error('Error checking manager status:', error);
      }
    };

    checkIfManager();
  }, [user]);

  if (!user) return null;

  return (
    <Navbar expand="lg" className="flex-column sidebar">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <FaBars />
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="flex-column w-100">
            {user.role === 'admin' && (
              <>
                <Nav.Item>
                  <Nav.Link as={Link} to="/companies">
                    <FaHome /> Admin Manage Companies
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to="/adminapproval">
                    <FaHandMiddleFinger /> Admin Approval
                  </Nav.Link>
                  <Nav.Link as={Link} to="/companyapplicant">
                    <FaUserTie /> Applicants Manager
                  </Nav.Link>
                </Nav.Item>
              </>
            )}
            {(user.role === 'boss' || isManager) && (
              <Nav.Item>
                <Nav.Link as={Link} to="/managecompanies">
                  <FaHome /> {user.role === 'boss' ? 'Boss' : 'Manager'} Manage Companies
                </Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item>
              <Nav.Link as={Link} to="/cashregister">
                <FaCashRegister /> Cash Register
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/userinfo">
                <FaUser /> User Info
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Sidebar;
