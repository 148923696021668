// src/components/Dashboard.js
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';
import '../styles.css';
import withAuth from '../hoc/withAuth';

const Dashboard = () => {
  const { user } = useContext(AuthContext);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const fetchCompanies = async () => {
      if (user) {
        const token = localStorage.getItem('token');
        try {
          const response = await axios.get('https://neonbs.org/api/companies/user', {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          setCompanies(response.data);
        } catch (error) {
          console.error('Error fetching companies:', error.response ? error.response.data : error.message);
        }
      }
    };

    fetchCompanies();
  }, [user]);

  if (!user) {
    return (
      <div>
        Please log in to access the dashboard.
        <p>
          Need to Login? <a href="/login">Click Here to login</a>
        </p>
      </div>
    );
  }

  return (
    <div className="dashboard">
      <h1>Welcome to the Dashboard </h1>
      <nav>
        <ul>
          {/* Links for all authenticated users */}
          <li><Link to="/cashregister">Cash Register</Link></li>

          {/* Links based on user roles */}
          {user.role === 'admin' && (
            <>
              <li><Link to="/companies">Manage Companies</Link></li>
              <li><Link to="/addinventory">Manage Inventory</Link></li>
              <li><Link to="/sales">View Sales</Link></li>
            </>
          )}
          {user.role === 'boss' && (
            <>
              {companies.map((company) => (
                <li key={company.id}>
                  <Link to={`/addinventory`}>{company.companyName}</Link>
                  <li><Link to={`/sales?ownerId=${user.id}`}>View My Sales</Link></li>
                </li>
              ))}
            </>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default withAuth(Dashboard);
