import React from 'react';
import { Card, Form, Button, Table } from 'react-bootstrap';

const AdminPanel = ({ users, companies, handleAddCompany, handleRoleChange, companyName, setCompanyName, selectedOwner, setSelectedOwner }) => {
  return (
    <Card className="mb-4">
      <Card.Body>
        <Card.Title>Add New Company</Card.Title>
        <Form onSubmit={handleAddCompany}>
          <Form.Group className="mb-3" controlId="formCompanyName">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter company name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formOwner">
            <Form.Label>Select Owner</Form.Label>
            <Form.Control
              as="select"
              value={selectedOwner}
              onChange={(e) => setSelectedOwner(e.target.value)}
              required
            >
              <option value="">Select Owner</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.username}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Button variant="primary" type="submit">
            Add Company
          </Button>
        </Form>
      </Card.Body>

      <Card.Body>
        <Card.Title>Assign Employees to Companies</Card.Title>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>User Name</th>
              {companies.map((company) => (
                <th key={company.id}>{company.companyName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.role === 'admin' ? `${user.username} (Admin)` : user.username}</td>
                {companies.map((company) => (
                  <td key={company.id}>
                    <Form.Check
                      type="checkbox"
                      label="Employee"
                      checked={company.employees.some(
                        (emp) => emp.id === user.id && emp.role === 'employee'
                      )}
                      onChange={(e) => handleRoleChange(user.id, company.id, 'employee', e.target.checked)}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default AdminPanel;
