import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import { Container, Tabs, Tab, Spinner, Alert } from 'react-bootstrap';
import AdminPanel from '../MC/AdminPanel';
import CompanyDetails from '../MC/CompanyDetails';
import EmployeeManagement from '../MC/EmployeeManagement';
import InventoryManagement from '../MC/InventoryManagement';
import CompanySettings from '../MC/CompanySettings';
import withAuth from '../../hoc/withAuth';
import { ThemeContext } from '../../contexts/ThemeContext';

const ManageCompanies = () => {
  const { theme } = useContext(ThemeContext);
  const [user, setUser] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [key, setKey] = useState('adminPanel');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [sales, setSales] = useState({});
  const [totalAmount, setTotalAmount] = useState({});
  const [totalQuantity, setTotalQuantity] = useState({});
  const [monthSales, setMonthSales] = useState({});
  const [monthQuantity, setMonthQuantity] = useState({});
  const [weekSales, setWeekSales] = useState({});
  const [weekQuantity, setWeekQuantity] = useState({});
  const [topSalesRank, setTopSalesRank] = useState({});
  const [inventory, setInventory] = useState({});
  const [isAddItemOpen, setIsAddItemOpen] = useState(false);
  const [itemName, setItemName] = useState('');
  const [itemPrice, setItemPrice] = useState('');
  const [itemQuantity, setItemQuantity] = useState('');
  const [image, setImage] = useState(null);
  const [editingItemId, setEditingItemId] = useState(null);
  const [webhookUrl, setWebhookUrl] = useState('');
  const [webhookUrlSaved, setWebhookUrlSaved] = useState(false);
  const [showWebhook, setShowWebhook] = useState(false);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get('https://neonbs.org/api/auth/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user info:', error);
        setError('Error fetching user info');
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  const fetchCompanies = useCallback(async () => {
    if (!user) return;
    try {
      const token = localStorage.getItem('token');
      const url =
        user.role === 'admin'
          ? 'https://neonbs.org/api/companies/admin'
          : 'https://neonbs.org/api/companies/user';
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      let filteredCompanies = response.data;

      // Filter companies based on the user's role
      if (user.role === 'boss') {
        filteredCompanies = filteredCompanies.filter(
          (company) =>
            company.ownerId === user.id ||
            (company.employees &&
              company.employees.some((employee) => employee.id === user.id && employee.UserCompanies.manager))
        );
      } else if (user.role === 'manager') {
        filteredCompanies = filteredCompanies.filter(
          (company) =>
            company.employees &&
            company.employees.some((employee) => employee.id === user.id && employee.UserCompanies.manager)
        );
      }

      setCompanies(filteredCompanies);

      if (filteredCompanies.length > 0) {
        setKey(filteredCompanies[0].id);
        setSelectedCompany(filteredCompanies[0].id);
        fetchWebhookUrl(filteredCompanies[0].id);
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
      setError('Error fetching companies: ' + error.message);
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchCompanies();
      if (user.role === 'admin') {
        const fetchUsers = async () => {
          const token = localStorage.getItem('token');
          try {
            const response = await axios.get('https://neonbs.org/api/users', {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            setUsers(response.data);
          } catch (error) {
            console.error('Error fetching users:', error);
            setError('Error fetching users: ' + error.message);
          }
        };
        fetchUsers();
      }
    }
  }, [user, fetchCompanies]);

  const handleRoleChange = async (userId, companyId, role, checked) => {
    const token = localStorage.getItem('token');

    try {
      if (checked) {
        await axios.put(
          `https://neonbs.org/api/companies/${companyId}/assign`,
          { userId, role },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
      } else {
        await axios.delete(
          `https://neonbs.org/api/companies/${companyId}/fire/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
      }

      setCompanies((prevCompanies) =>
        prevCompanies.map((company) =>
          company.id === companyId
            ? {
                ...company,
                employees: checked
                  ? company.employees.concat({
                      id: userId,
                      username: users.find((u) => u.id === userId)?.username,
                      role,
                    })
                  : company.employees.filter((emp) => emp.id !== userId),
              }
            : company
        )
      );
    } catch (error) {
      console.error('Error updating role:', error);
      setError('Error updating role: ' + error.message);
    }
  };

  const handleFireEmployee = async (companyId, userId) => {
    const token = localStorage.getItem('token');

    try {
      await axios.delete(
        `https://neonbs.org/api/companies/${companyId}/fire/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      fetchCompanies();
    } catch (error) {
      console.error('Error firing employee:', error);
      setError('Error firing employee: ' + error.message);
    }
  };

  const handleDeleteSale = async (saleId, companyId) => {
    const token = localStorage.getItem('token');

    try {
      await axios.delete(`https://neonbs.org/api/sales/${saleId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      fetchSales(companyId);
    } catch (error) {
      console.error('Error deleting sale:', error);
      setError('Error deleting sale: ' + error.message);
    }
  };

  const fetchInventory = useCallback(async (companyId) => {
    if (companyId) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(
          `https://neonbs.org/api/inventory/company/${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        setInventory((prevInventory) => ({
          ...prevInventory,
          [companyId]: response.data,
        }));
      } catch (error) {
        console.error('Error fetching inventory:', error);
        setError('Error fetching inventory: ' + error.message);
      }
    }
  }, []);

  const fetchWebhookUrl = useCallback(async (companyId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `https://neonbs.org/api/companies/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setWebhookUrl(response.data.webhookUrl || '');
    } catch (error) {
      console.error('Error fetching webhook URL:', error);
      setError('Error fetching webhook URL: ' + error.message);
    }
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      fetchInventory(selectedCompany);
      fetchWebhookUrl(selectedCompany);
    }
  }, [selectedCompany, fetchInventory, fetchWebhookUrl]);

  const fetchSales = async (companyId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `https://neonbs.org/api/sales/company/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          params: {
            startDate: startDate.toISOString().split('T')[0],
            endDate: endDate.toISOString().split('T')[0],
          },
        }
      );

      let salesData = response.data;

      if (user.role === 'employee') {
        salesData = salesData.filter((sale) => sale.userId === user.id);
      }

      const totalAmt = salesData.reduce(
        (acc, sale) => acc + parseFloat(sale.price) * sale.quantity,
        0
      );
      const totalQty = salesData.reduce((acc, sale) => acc + sale.quantity, 0);

      const currentMonth = new Date().getMonth();
      const monthSalesData = salesData.filter(
        (sale) => new Date(sale.createdAt).getMonth() === currentMonth
      );
      const monthAmt = monthSalesData.reduce(
        (acc, sale) => acc + parseFloat(sale.price) * sale.quantity,
        0
      );
      const monthQty = monthSalesData.reduce(
        (acc, sale) => acc + sale.quantity,
        0
      );

      const currentWeekStart = new Date();
      currentWeekStart.setDate(currentWeekStart.getDate() - currentWeekStart.getDay());
      const currentWeekEnd = new Date(currentWeekStart);
      currentWeekEnd.setDate(currentWeekEnd.getDate() + 6);
      const weekSalesData = salesData.filter((sale) => {
        const saleDate = new Date(sale.createdAt);
        return saleDate >= currentWeekStart && saleDate <= currentWeekEnd;
      });
      const weekAmt = weekSalesData.reduce(
        (acc, sale) => acc + parseFloat(sale.price) * sale.quantity,
        0
      );
      const weekQty = weekSalesData.reduce(
        (acc, sale) => acc + sale.quantity,
        0
      );

      const userSalesMap = salesData.reduce((acc, sale) => {
        if (!acc[sale.userId]) {
          acc[sale.userId] = { username: sale.User.username, totalSales: 0 };
        }
        acc[sale.userId].totalSales +=
          parseFloat(sale.price) * sale.quantity;
        return acc;
      }, {});

      const topSalesRank = Object.values(userSalesMap).sort(
        (a, b) => b.totalSales - a.totalSales
      );

      setSales((prevSales) => ({
        ...prevSales,
        [companyId]: salesData,
      }));
      setTotalAmount((prevAmount) => ({
        ...prevAmount,
        [companyId]: totalAmt,
      }));
      setTotalQuantity((prevQuantity) => ({
        ...prevQuantity,
        [companyId]: totalQty,
      }));
      setMonthSales((prevAmount) => ({
        ...prevAmount,
        [companyId]: monthAmt,
      }));
      setMonthQuantity((prevQuantity) => ({
        ...prevQuantity,
        [companyId]: monthQty,
      }));
      setWeekSales((prevAmount) => ({
        ...prevAmount,
        [companyId]: weekAmt,
      }));
      setWeekQuantity((prevQuantity) => ({
        ...prevQuantity,
        [companyId]: weekQty,
      }));
      setTopSalesRank((prevRank) => ({
        ...prevRank,
        [companyId]: topSalesRank,
      }));
    } catch (error) {
      console.error('Error fetching sales:', error);
      setError('Error fetching sales: ' + error.message);
    }
  };

  const handleAddCompany = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    try {
      await axios.post(
        'https://neonbs.org/api/companies',
        { companyName, ownerId: selectedOwner },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setCompanyName('');
      setSelectedOwner('');
      fetchCompanies();
    } catch (error) {
      console.error('Error adding company:', error);
      setError('Error adding company: ' + error.message);
    }
  };

  const handleAddInventoryItem = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const formData = new FormData();

    if (image) {
      if (image.size > 2 * 1024 * 1024) {
        setError('File size exceeds 2 MB limit.');
        return;
      }
      formData.append('image', image);
    }

    formData.append('companyId', selectedCompany);
    formData.append('itemName', itemName);
    formData.append('itemPrice', itemPrice);
    formData.append('itemQuantity', itemQuantity);

    try {
      if (editingItemId) {
        await axios.put(
          `https://neonbs.org/api/inventory/${editingItemId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        setEditingItemId(null);
      } else {
        await axios.post('https://neonbs.org/api/inventory', formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      alert('Inventory item saved successfully');
      setItemName('');
      setItemPrice('');
      setItemQuantity('');
      setImage(null);
      fetchInventory(selectedCompany);
    } catch (error) {
      console.error('Error saving inventory item:', error);
      setError('Error saving inventory item: ' + error.message);
    }
  };

  const handleDeleteInventoryItem = async (id, companyId) => {
    const token = localStorage.getItem('token');

    try {
      await axios.delete(`https://neonbs.org/api/inventory/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setInventory((prevInventory) => ({
        ...prevInventory,
        [companyId]: prevInventory[companyId].filter((item) => item.id !== id),
      }));
    } catch (error) {
      console.error('Error deleting inventory item:', error);
      setError('Error deleting inventory item: ' + error.message);
    }
  };

  const handleEditInventoryItem = (item) => {
    setEditingItemId(item.id);
    setSelectedCompany(item.companyId);
    setItemName(item.itemName);
    setItemPrice(item.itemPrice);
    setItemQuantity(item.itemQuantity);
    setImage(null);
    setIsAddItemOpen(true);
  };

  const handleTabSelect = async (key) => {
    setKey(key);
    if (key !== 'adminPanel') {
      setSelectedCompany(key);
      await fetchSales(key);
      await fetchInventory(key);
      await fetchWebhookUrl(key);
    }
  };

  if (loading || !user) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  return (
    <Container className={`mt-5 ${theme}`}>
      <h1 className="text-center mb-4">{editingItemId ? 'Edit Inventory Item' : 'Company Dashboard'}</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      <Tabs activeKey={key} onSelect={(k) => handleTabSelect(k)} className="mb-3">
        {user.role === 'admin' && (
          <Tab eventKey="adminPanel" title="Admin Panel">
            <AdminPanel
              users={users}
              companies={companies}
              handleAddCompany={handleAddCompany}
              handleRoleChange={handleRoleChange}
              companyName={companyName}
              setCompanyName={setCompanyName}
              selectedOwner={selectedOwner}
              setSelectedOwner={setSelectedOwner}
            />
          </Tab>
        )}
        {companies.map((company) => (
          <Tab key={company.id} eventKey={company.id} title={company.companyName}>
            <Tabs defaultActiveKey="sales" className="mb-3">
              <Tab eventKey="sales" title="Sales">
                <CompanyDetails
                  company={company}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  fetchSales={fetchSales}
                  sales={sales}
                  monthSales={monthSales}
                  monthQuantity={monthQuantity}
                  weekSales={weekSales}
                  weekQuantity={weekQuantity}
                  topSalesRank={topSalesRank}
                  handleDeleteSale={handleDeleteSale}
                  user={user}
                />
              </Tab>
              {(user.role === 'boss' || (user.role === 'manager' && company.employees.some(employee => employee.id === user.id && employee.UserCompanies.manager))) && (
                <Tab eventKey="employees" title="Employees">
                  <EmployeeManagement 
                    company={company} 
                    handleFireEmployee={handleFireEmployee} 
                    user={user} 
                  />
                </Tab>
              )}
              {(user.role === 'boss' || user.role === 'manager') && (
                <Tab eventKey="addInventory" title="Add Inventory">
                  <InventoryManagement
                    inventory={inventory}
                    selectedCompany={selectedCompany}
                    itemName={itemName}
                    setItemName={setItemName}
                    itemPrice={itemPrice}
                    setItemPrice={setItemPrice}
                    itemQuantity={itemQuantity}
                    setItemQuantity={setItemQuantity}
                    image={image}
                    setImage={setImage}
                    handleAddInventoryItem={handleAddInventoryItem}
                    isAddItemOpen={isAddItemOpen}
                    setIsAddItemOpen={setIsAddItemOpen}
                    handleDeleteInventoryItem={handleDeleteInventoryItem}
                    handleEditInventoryItem={handleEditInventoryItem}
                    editingItemId={editingItemId}
                  />
                </Tab>
              )}
              {user.role === 'admin' && (
                <Tab eventKey="settings" title="Settings">
                  <CompanySettings
                    company={company}
                    webhookUrl={webhookUrl}
                    setWebhookUrl={setWebhookUrl}
                    showWebhook={showWebhook}
                    toggleShowWebhook={setShowWebhook}
                    handleSaveWebhook={setWebhookUrlSaved}
                  />
                </Tab>
              )}
            </Tabs>
          </Tab>
        ))}
      </Tabs>
    </Container>
  );
};

export default withAuth(ManageCompanies);
