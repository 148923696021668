import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles.css';
import withAuth from '../hoc/withAuth';
import { Container, Form, Button, Alert, Spinner, Row, Col } from 'react-bootstrap';

const UserInfo = () => {
  const [user, setUser] = useState(null);
  const [cityName, setCityName] = useState('');
  const [email, setEmail] = useState(''); // New state for email
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchUserInfo = async () => {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://neonbs.org/api/auth/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser(response.data);
      setCityName(response.data.cityName || '');
      setEmail(response.data.email || ''); // Load the user's current email
    };

    fetchUserInfo();
  }, []);

  const handleCityNameChange = (e) => {
    setCityName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    if (name === 'currentPassword') setCurrentPassword(value);
    if (name === 'newPassword') setNewPassword(value);
    if (name === 'confirmPassword') setConfirmPassword(value);
  };

  const handleUpdateCityName = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      await axios.put('https://neonbs.org/api/user/update-city', { cityName }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMessage('City name updated successfully!');
    } catch (error) {
      console.error('Error updating city name:', error);
      setMessage('Failed to update city name.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateEmail = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      await axios.put('https://neonbs.org/api/user/update-email', { email }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMessage('Email updated successfully!');
    } catch (error) {
      console.error('Error updating email:', error);
      setMessage('Failed to update email.');
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setMessage('New passwords do not match.');
      return;
    }

    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      await axios.put('https://neonbs.org/api/user/change-password', { currentPassword, newPassword }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMessage('Password updated successfully!');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Error updating password:', error);
      setMessage('Failed to update password.');
    } finally {
      setLoading(false);
    }
  };

  if (!user) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Container className="mt-5">
      <h1 className="text-center mb-4">User Information</h1>
      <Row>
        <Col xs={12} sm={10} md={8} lg={6}>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Username: </Form.Label>
              <Form.Label> {user.username}</Form.Label> 
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Role: </Form.Label>
              <Form.Label> {user.role} </Form.Label>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                id="cityName"
                value={cityName}
                onChange={handleCityNameChange}
                disabled={loading}
              />
            </Form.Group>
            <Button variant="primary" onClick={handleUpdateCityName} disabled={loading} className="w-100">
              {loading ? 'Updating...' : 'Update City Name'}
            </Button>

            <hr />

            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                disabled={loading}
              />
            </Form.Group>
            <Button variant="primary" onClick={handleUpdateEmail} disabled={loading} className="w-100">
              {loading ? 'Updating...' : 'Update Email'}
            </Button>

            <hr />

            <h3 className="mt-4">Change Password</h3>
            <Form.Group className="mb-3">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type="password"
                name="currentPassword"
                value={currentPassword}
                onChange={handlePasswordChange}
                disabled={loading}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                name="newPassword"
                value={newPassword}
                onChange={handlePasswordChange}
                disabled={loading}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={handlePasswordChange}
                disabled={loading}
              />
            </Form.Group>
            <Button variant="danger" onClick={handleChangePassword} disabled={loading} className="w-100">
              {loading ? 'Updating...' : 'Change Password'}
            </Button>

            {message && (
              <Alert variant={message.includes('successfully') ? 'success' : 'danger'} className="mt-3">
                {message}
              </Alert>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default withAuth(UserInfo);
