import React from 'react';
import { Container, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../styles.css';

const TermsAndConditions = () => {
  const navigate = useNavigate();

  const handleAgree = () => {
    localStorage.setItem('agreedToTerms', 'true'); // Set this value when the user agrees
    navigate('/register');
  };

  return (
    <Container className="mt-5">
      <Card className="p-4 shadow-sm">
        <Card.Body>
          <h2 className="text-center mb-4">Terms and Conditions</h2>
          <p>Welcome to Neon Business Services ("we", "our", "us"). These Terms and Conditions ("Terms") govern your use of our website and services. By accessing or using our services, you agree to comply with and be bound by these Terms. If you disagree with any part of the Terms, you must not use our services.</p>
          
          <h4>1. Introduction</h4>
          <p>Welcome to Neon Business Services ("we", "our", "us"). These Terms and Conditions ("Terms") govern your use of our website and services. By accessing or using our services, you agree to comply with and be bound by these Terms.</p>

          <h4>2. Services</h4>
          <p>Neon Business Services provides a range of business solutions and services. The specific services we offer may change from time to time. We reserve the right to modify, suspend, or discontinue any part of our services at any time without prior notice.</p>

          <h4>3. User Responsibilities</h4>
          <ul>
            <li><strong>Account Information:</strong> You are responsible for maintaining the confidentiality of your account information, including your username and password.</li>
            <li><strong>Prohibited Activities:</strong> You agree not to use our services for any unlawful purpose or in any way that could harm, disrupt, or overburden our operations.</li>
            <li><strong>Accuracy of Information:</strong> You agree to provide accurate and up-to-date information when registering or using our services.</li>
          </ul>

          <h4>4. Payment Terms</h4>
          <p>All prices for our services are clearly stated on our website or provided during the contract negotiation. Payment is due according to the terms specified in your agreement with Neon Business Services. Late payments may result in suspension or termination of services.</p>

          <h4>5. Termination</h4>
          <p>We may terminate or suspend your access to our services immediately, without prior notice, if you breach any of these Terms. Upon termination, your right to use our services will cease immediately.</p>

          <h4>6. Limitation of Liability</h4>
          <p>To the fullest extent permitted by law, Neon Business Services shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use or inability to use the services.</p>

          <h4>7. Intellectual Property</h4>
          <p>All content and materials provided by Neon Business Services, including but not limited to text, graphics, logos, and software, are the intellectual property of Neon Business Services or its licensors. You may not use any of this content without our express written permission.</p>

          <h4>8. Privacy Policy</h4>
          <p>Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and protect your personal information.</p>

          <h4>9. Governing Law</h4>
          <p>These Terms shall be governed by and construed in accordance with the laws, without regard to its conflict of law provisions.</p>

          <h4>10. Changes to Terms</h4>
          <p>Neon Business Services reserves the right to modify these Terms at any time. Any changes will be effective immediately upon posting on our website. Your continued use of our services following the posting of any changes constitutes your acceptance of such changes.</p>

          <Button variant="primary" onClick={handleAgree} className="mt-4">I Agree</Button>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TermsAndConditions;
